import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation(); // Get the current location
  const [activeLink, setActiveLink] = useState(location.pathname); // Set initial active link based on location

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    setActiveLink(location.pathname); // Update active link when location changes
  }, [location]);

  return (
    <div className="w-60 bg-white font-nunito text-[#202224] font-semibold flex flex-col px-4 lg:w-60 fixed h-[calc(100vh-4rem)] mt-[4.5rem]">
      <nav className="text-sm mt-2">
        <Link
          to="/"
          className={`block py-3 px-4 rounded-md text-sm transition-colors duration-300 ${
            activeLink === "/"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/")}
        >
          Dashboard
        </Link>
        <Link
          to="/consumer"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/consumer"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/consumer")}
        >
          Consumer
        </Link>
        <Link
          to="/service-provider"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/service-provider"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/service-provider")}
        >
          Service Provider
        </Link>
        <Link
          to="/services"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/services"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/services")}
        >
          Services
        </Link>
        <Link
          to="/finance"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/finance"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/finance")}
        >
          Finances
        </Link>
        <Link
          to="/help-support"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/help-support"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/help-support")}
        >
          Help & Support
        </Link>
        <Link
          to="/content-manage"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/content-manage"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/content-manage")}
        >
          Content
        </Link>
        <Link
          to="/notifications"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/notifications"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/notifications")}
        >
          Notifications
        </Link>
        <Link
          to="/role-management"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/role-management"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/role-management")}
        >
          Role Management
        </Link>
        <Link
          to="/settings"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/settings"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/settings")}
        >
          Settings
        </Link>
        <Link
          to="/logout"
          className={`block py-3 px-4 rounded-md transition-colors duration-300 ${
            activeLink === "/logout"
              ? "bg-gradient-1 text-white"
              : "hover:bg-gradient-1"
          }`}
          onClick={() => handleLinkClick("/logout")}
        >
          Logout
        </Link>
      </nav>
    </div>
  );
}

export default Sidebar;
