import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import { getStatusColor } from "../../statusUtils";

const UploadDocument = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mode = query.get("mode") || "upload";
  const consumerData = location.state || {};

  return (
    <div className="flex flex-col items-center   ">
      {/* Header */}
      <div className="flex items-center justify-between self-auto w-full  md:mb-8">
        <div className="flex items-center space-x-6 mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="22"
            viewBox="0 0 13 22"
            fill="none"
            onClick={() => navigate(-1)} // Go back to the previous page
            className="cursor-pointer"
          >
            <path d="M12 1L2 11L12 21" stroke="#202224" strokeWidth="2" />
          </svg>
          <h2 className="text-3xl  font-bold font-nunito text-[#202224]">
            {mode === "upload"
              ? "Upload Documents"
              : `${consumerData.username} - Documents`}
          </h2>
        </div>
        <span
          className={`  ${getStatusColor(
            consumerData?.status
          )} text-white font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center `}
        >
          {consumerData?.status}
        </span>
      </div>

      {/* Document Sections */}
      <div className="w-full space-y-6 bg-white p-8 rounded-2xl h-screen">
        {/* Electricity Bill Section */}
        <div className="flex flex-col items-start space-y-2">
          <label
            htmlFor="electricity-bill"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Electricity Bill
          </label>
          {mode === "upload" ? (
            <>
              <input
                type="file"
                id="electricity-bill"
                className="hidden "
                onChange={(e) => console.log("Selected")}
              />
              <label
                htmlFor="electricity-bill"
                className="block bg-[#F5F6FA] w-3/4 text-left px-4 py-2 text-green font-lato font-semibold text-sm  border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
              >
                Upload
              </label>
            </>
          ) : (
            <div className="w-3/4 flex justify-between text-left px-4 py-4 bg-gray-100 text-sm border border-gray-300 rounded">
              <p> Electricity Bill Document</p>
              <button className="text-green text-sm font-semibold font-lato">
                View
              </button>
            </div>
          )}
        </div>

        {/* Aadhaar Card Section */}
        <div className="flex flex-col items-start space-y-2">
          <label
            htmlFor="aadhaar-card"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Aadhaar Card
          </label>
          {mode === "upload" ? (
            <>
              <input
                type="file"
                id="aadhaar-card"
                className="hidden"
                onChange={(e) => console.log("Selected")}
              />
              <label
                htmlFor="aadhaar-card"
                className="block bg-[#F5F6FA] text-green font-lato font-semibold  w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
              >
                Upload
              </label>
            </>
          ) : (
            <div className="flex justify-between w-3/4 text-left px-4 py-4 bg-gray-100 text-sm border border-gray-300 rounded">
              <p> Aadhaar Card Document</p>
              <button className="text-green text-sm font-semibold font-lato">
                View
              </button>
            </div>
          )}
        </div>

        {/* Button Group */}
        {mode === "upload" && (
          <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:pt-16">
            <ButtonGroup
              buttons={[
                // {
                //   label: "Discard",
                //   variant: "discard",
                // },
                // { label: "Delete", variant: "delete" },
                { label: "Save", variant: "save" },
              ]}
            />
          </div>
        )}

        {mode === "view" && (
          <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:pt-16">
            <ButtonGroup
              buttons={[
                {
                  label: "Delete",
                  variant: "discard",
                },
                {
                  label: "Reject",
                  variant: "delete",
                  disabled:
                    consumerData?.status === "verified" ||
                    consumerData?.status === "rejected",
                },
                {
                  label:
                    consumerData?.status === "verified" ? "Verified" : "Verify",
                  variant: "save",
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDocument;
