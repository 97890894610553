import React from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";

const NotificationList = () => {
  const navigate = useNavigate();

  const notifications = [
    {
      id: 1,
      title: "New Message",
      description: "You have received a new message.",
    },
    {
      id: 2,
      title: "Order Update",
      description: "Your order has been shipped.",
    },
    {
      id: 3,
      title: "New Connection",
      description: "You have a new connection request.",
    },
    {
      id: 4,
      title: "Payment Successful",
      description: "Your payment was successfully processed.",
    },
    {
      id: 5,
      title: "Reminder",
      description: "Don't forget your meeting at 3 PM.",
    },
    {
      id: 6,
      title: "Promotion",
      description: "Check out our latest promotions!",
    },
  ];

  return (
    <div className="">
      <div className="flex justify-between items-center mb-8">
        <Title title="Notifications" goBack={() => navigate(-1)} />

        <div className="flex justify-end pr-4 text-[#08875D] font-semibold text-sm cursor-pointer">
          Mark All As Read
        </div>
      </div>

      <div className="mt-4 mb-16">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className="flex items-center p-4 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
          >
            <div className="rounded-full bg-gray-300 w-10 h-10 flex-shrink-0"></div>
            <div className="ml-4">
              <div className="font-bold text-gray-800">
                {notification.title}
              </div>
              <div className="text-sm text-gray-600">
                {notification.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationList;
