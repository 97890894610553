import React, { useState } from "react";
import Title from "../../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";

import del from "../../../../assets/del.png";
import edit from "../../../../assets/edit.png";
import ButtonGroup from "../../../Buttons/ButtonGroup";

const DeclineLeaves = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = location.state || { userType: "Consumer" };

  const [terms, setTerms] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  );

  const [reasons, setReasons] = useState([
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  ]);

  const handleEditTerms = () => {
    const updatedTerms = prompt("Edit Terms and Conditions:", terms);
    if (updatedTerms) setTerms(updatedTerms);
  };

  const handleEditReason = (index) => {
    const updatedReason = prompt("Edit Reason:", reasons[index]);
    if (updatedReason) {
      const updatedReasons = [...reasons];
      updatedReasons[index] = updatedReason;
      setReasons(updatedReasons);
    }
  };

  const handleDeleteReason = (index) => {
    const updatedReasons = reasons.filter((_, i) => i !== index);
    setReasons(updatedReasons);
  };

  const handleAddReason = () => {
    const newReason = prompt("Add a new reason:");
    if (newReason) setReasons([...reasons, newReason]);
  };

  return (
    <div className="">
      {/* Header */}
      <div className="flex justify-between">
        <div className="flex gap-8">
          <Title
            title="Decline Client Request Reasons"
            goBack={() => navigate(-1)}
          />
          <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
            {userType}
          </button>
        </div>
      </div>

      {/* Content Section */}
      <div className="bg-white rounded-2xl p-8 mt-9 mb-8">
        {/* Terms and Conditions */}
        <div className="mb-6">
          <div className="flex justify-between items-center">
            <p className="text-sm text-[#606060] font-semibold">
              Terms and Conditions
            </p>
            <div className="flex gap-2">
              <button
                className="text-sm text-blue-500 underline"
                // onClick={handleDelTerms}
              >
                <img src={del} className="w-5 h-5" />
              </button>

              <button
                className="text-sm text-blue-500 underline"
                onClick={handleEditTerms}
              >
                <img src={edit} className="w-5 h-5" />
              </button>
            </div>
          </div>
          <p className="text-gray-600 text-sm mt-2">{terms}</p>
        </div>

        {/* Reasons */}
        {reasons.map((reason, index) => (
          <div
            key={index}
            className="flex justify-between items-center bg-gray-100 rounded-lg p-4 mb-4"
          >
            <p className="text-gray-600 text-sm">{reason}</p>
            <div className="flex gap-2">
              <button
                className="text-sm text-blue-500 underline"
                onClick={() => handleEditReason(index)}
              >
                <img src={edit} className="w-5 h-5" />
              </button>
              <button
                className="text-sm text-red-500 underline"
                onClick={() => handleDeleteReason(index)}
              >
                <img src={del} className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}

        {/* Add Reason Button */}
        <button
          style={{ background: `rgba(8, 135, 93, 0.15)` }}
          className=" text-green border w-44 border-green border-dashed py-2 px-6 rounded-lg mt-4"
          onClick={handleAddReason}
        >
          Add Reason
        </button>

        <div className="mt-[234px]">
          <ButtonGroup
            buttons={[
              {
                label: "Discard",
                variant: "delete",
                // onClick: () => setIsEditing(false),
              },
              { label: "Save", variant: "save" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DeclineLeaves;
