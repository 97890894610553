// src/data/totalData.js
import ConsumersIcon from "../../assets/Icon.png";
import SellersIcon from "../../assets/roleIcon.png";
import ProductsIcon from "../../assets/greenIcon.png";
import OrdersIcon from "../../assets/newTicket.png";

export const totalData = [
  {
    title: "Total Consumers (Users)",
    icon: ConsumersIcon,
    count: "40,689",
  },
  {
    title: "Total Service Providers (Maids)",
    icon: SellersIcon,
    count: "12,345",
  },
  {
    title: "New Users(Consumers + Service Providers)",
    icon: ProductsIcon,
    count: "8,976",
  },
  {
    title: "Total Services ",
    icon: OrdersIcon,
    count: "25,482",
  },
];
