import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import badgeData from "../../../data/content/badge";
import Title from "../../../Title/Title";
import arrow from "../../../../assets/greenarrow.png";

const AssessmentResultPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = location.state || { userType: "Consumer" };
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-8">
          <div>
            <Title
              title="Assessment Result (Badges)"
              goBack={() => navigate(-1)}
            />
          </div>
          <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
            {userType}
          </button>
        </div>
      </div>

      <div className="bg-white rounded-2xl p-8 mt-9 h-screen mb-8">
        <div>
          <p className="text-sm text-[#606060] font-semibold font-nunito">
            Total Badges
          </p>
        </div>

        <div className="mt-4">
          {badgeData.map((badge, index) => (
            <div className="flex justify-between" key={index}>
              <div
                className="font-nunito flex justify-between font-normal w-4/5 bg-[#F5F6FA] text-black text-sm p-4 mb-2 rounded border-gray-300 cursor-pointer"
                onClick={() =>
                  navigate("/assessment-result-detail", {
                    state: { badgeId: badge.id, userType },
                  })
                }
              >
                <span>{badge.badge}</span>
                <img src={arrow} className="w-5 h-5" alt="Arrow" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssessmentResultPage;
