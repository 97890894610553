import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import filterIcon from "../../assets/filter.png";
import searchIcon from "../../assets/search.png";
import * as XLSX from "xlsx";

const GenericTable = ({
  title,
  data,
  columns,
  showFilters = true,
  reviewText = "Review",
  redirectTo,
  itemsPerPage = 5,
}) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    category: "",
    status: "",
    searchText: "",
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "verified":
      case "Completed":
      case "Redeemed":
      case "Resolved":
        return "bg-[#08875D] text-white";
      case "pending":
      case "In Progress":
      case "Pending":
      case "Upcoming":
      case "Unused":
        return "bg-[#FCBE2D] text-white";
      case "rejected":
      case "Replaced":
      case "Expired":
      case "Rejected":
        return "bg-[#FD5454] text-white";
      case "Request Rejected":
        return "border border-[#FD5454] text-[#fd5454]";
      case "Request Pending":
        return "border border-[#08875D] text-green";
      default:
        return "bg-gray-600";
    }
  };
  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "data_export.xlsx");
  };
  const handleReviewClick = (rowData) => {
    if (redirectTo) {
      navigate(redirectTo, {
        state: rowData,
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilterChange = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const filteredData = data.filter((item) => {
    const matchesSearch = item.name
      ?.toLowerCase()
      .includes(searchText.toLowerCase());
    const matchesCategory =
      !selectedCategory || item.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="py-6">
      {showFilters && (
        <div className="flex justify-end mb-4">
          <div className="flex justify-between items-center gap-4">
            <div className="relative flex">
              <button
                onClick={handleExport}
                className="text-[#344054] mr-6 border text-sm font-lato font-normal h-10 px-5 rounded-lg border-[#D0D5DD] flex items-center justify-center"
              >
                Export
              </button>
              <button
                className="border bg-white border-[#D0D5DD] text-[#344054] font-lato text-sm px-4 py-2 rounded-md font-medium flex items-center gap-2"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <img src={filterIcon} alt="Filter" className="h-4 w-4" />
                <span>Filters</span>
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-[2.6rem] bg-white w-52 rounded-lg shadow-md px-4 py-1 z-10">
                  <label
                    className="flex justify-between py-2 cursor-pointer"
                    onClick={() => handleFilterChange("Consumer")}
                  >
                    <span className="text-base font-lato font-normal">
                      Consumer
                    </span>
                    <input
                      type="radio"
                      name="category"
                      value="Consumer"
                      checked={selectedCategory === "Consumer"}
                      readOnly
                    />
                  </label>
                  <label
                    className="flex items-center justify-between py-2 cursor-pointer"
                    onClick={() => handleFilterChange("Service Provider")}
                  >
                    <span className="text-base font-lato font-normal">
                      Service Provider
                    </span>
                    <input
                      type="radio"
                      name="category"
                      value="Service Provider"
                      checked={selectedCategory === "Service Provider"}
                      readOnly
                    />
                  </label>
                </div>
              )}
            </div>

            <div className="relative flex items-center">
              <img
                src={searchIcon}
                alt="Search"
                className="absolute left-3 h-5 w-5"
              />
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={handleSearchChange}
                className="w-full border bg-white border-[#F4F4F4] rounded-lg pl-10 pr-4 py-2 placeholder:text-base placeholder:text-[#667085]"
              />
            </div>
          </div>
        </div>
      )}

      {/* Table Title */}
      {title && <h2 className="text-2xl font-semibold mb-4">{title}</h2>}

      {/* Table */}
      <div className="overflow-x-auto bg-white py-6 md:px-7 rounded-xl">
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-[#F1F4F9]">
            <tr className="border rounded-3xl border-transparent">
              {columns.map((col) => (
                <th
                  key={col.key}
                  className="px-4 py-5 border-gray-300 text-[#202224] text-left font-bold font-lato md:text-sm"
                >
                  {col.header}
                </th>
              ))}
              <th className="px-4 py-5 border-gray-300 text-left text-gray-700 font-medium">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((row, index) => (
              <tr key={index} className="hover:bg-gray-50">
                {columns.map((col) => (
                  <td
                    key={col.key}
                    className="px-4 py-5 border-b border-gray-300 font-nunito text-[#202224] font-semibold md:text-sm"
                  >
                    {col.key === "status" ||
                    col.key === "refundStatus" ||
                    col.key === "paymentStatus" ? (
                      <span
                        className={`${getStatusColor(
                          row[col.key]
                        )} px-3 py-1 rounded-xl font-semibold`}
                      >
                        {row[col.key]}
                      </span>
                    ) : (
                      row[col.key] || "-"
                    )}
                  </td>
                ))}
                <td className="px-4 py-5 border-b border-gray-300">
                  <button
                    onClick={() => handleReviewClick(row)}
                    className="text-green font-normal font-lato hover:underline"
                  >
                    {reviewText}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`w-10 h-10 mx-1 rounded-full flex items-center justify-center font-semibold font-lato text-sm ${
              currentPage === page ? "bg-black text-white" : "bg-white"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default GenericTable;
