import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import UploadButton from "../../Buttons/UploadButton";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import { totalNotificationData } from "../../data/notification/NotificationStat";
import GenericTable from "../../GenericTable/GenericTable";
import userData from "../../data/notification/NotificationTable";

const NotificationManagementPage = () => {
  const navigate = useNavigate();

  const columns = [
    { header: "S.No.", key: "id" },
    { header: "Notification Title", key: "name" },
    { header: "Date", key: "date" },
    { header: "Time", key: "time" },
  ];
  const [activeButton, setActiveButton] = useState("consumer");
  const handleRowClick = (row) => {
    navigate("/review-notification", {
      state: {
        userType: activeButton === "consumer" ? "Consumer" : "Service Provider",
        notificationTitle: row.name,
      },
    });
  };

  return (
    <div>
      <div className="flex flex-row gap-4">
        <Title title="Notifications" goBack={() => navigate(-1)} />

        <div
          className={`rounded-lg ${
            activeButton === "consumer"
              ? "bg-black text-white"
              : "bg-white text-black"
          } text-black font-lato item-center`}
        >
          <button
            className={`px-10 text-sm py-2 font-bold rounded-lg ${
              activeButton === "consumer" ? "text-white" : "text-black"
            }`}
            onClick={() => setActiveButton("consumer")}
          >
            CONSUMER
          </button>
        </div>

        <div
          className={`rounded-lg ${
            activeButton === "serviceProvider"
              ? "bg-black text-white"
              : "bg-white text-black"
          } border border-black text-white font-lato item-center`}
        >
          <button
            className={`px-10 text-sm py-2 font-bold  rounded-lg ${
              activeButton === "serviceProvider" ? "text-white" : "text-black"
            }`}
            onClick={() => setActiveButton("serviceProvider")}
          >
            SERVICE PROVIDER
          </button>
        </div>
        <div className="ml-[90px]">
          <button
            className="rounded-[8px] bg-green md:py-2 text-sm font-bold md:px-10 text-white"
            onClick={() =>
              navigate("/send-notification", {
                state: {
                  userType:
                    activeButton === "consumer"
                      ? "Consumer"
                      : "Service Provider",
                },
              })
            }
          >
            + Send New Notification
          </button>
        </div>
      </div>

      <div className="container mt-12">
        {activeButton === "consumer" ? (
          <>
            <div>
              <TotalStat data={totalNotificationData} />
            </div>
            <div
              onClick={(e) => {
                const rowIndex = e.target.closest("tr")?.rowIndex;
                if (rowIndex > 0) {
                  handleRowClick(userData[rowIndex - 1]);
                }
              }}
            >
              <GenericTable
                title=""
                columns={columns}
                data={userData}
                // redirectTo="/review-notification"
                showFilters={true}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <TotalStat data={totalNotificationData} />
            </div>

            <div
              onClick={(e) => {
                const rowIndex = e.target.closest("tr")?.rowIndex;
                if (rowIndex > 0) {
                  handleRowClick(userData[rowIndex - 1]);
                }
              }}
            >
              <GenericTable
                title=""
                columns={columns}
                data={userData}
                // redirectTo="/review-notification"
                showFilters={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationManagementPage;
