import React from "react";
import logOut from "../../../assets/logoutImg.png";

export default function LogoutConfirmation() {
  return (
    <div className="flex items-center justify-center min-h-screen ">
      <div className="flex flex-col md:flex-row p-6">
        <div className="flex flex-col items-center justify-center md:w-1/2 p-4">
          <img
            src={logOut}
            alt="Logout Illustration"
            className="w-full max-w-sm"
          />
        </div>

        <div
          className="flex flex-col justify-center md:w-[548px] p-4 border border-[#A6A6A6]  shadow-md rounded-3xl"
          // style={{
          //   border: "0px solid rgba(255, 255, 255, 0.10)",
          //   background: "rgba(255, 255, 255, 0.15)",
          //   backdropFilter: "blur(8.7px)",
          //   zIndex: 10,
          // }}
        >
          <div className="text-center md:text-left">
            <h1 className="text-3xl font-lato font-bold mb-5 text-center">
              Log out of your account
            </h1>
            <p className="text-[#7B7B7B] text-base font-semibold mb-10 text-center">
              Are you sure you want to log out of MaidEaze Admin?
            </p>
            <div className="flex flex-col items-center gap-4">
              <button className="bg-black w-96 rounded-lg text-white px-4 py-2  font-lato text-xl font-bold">
                Logout
              </button>
              <button className="font-lato text-xl font-bold text-green px-4 py-2">
                No, keep me logged in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
