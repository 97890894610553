import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import profileImg from "../../../assets/profile.png";
import adminImg from "../../../assets/adminImg.png";
import attach from "../../../assets/attach.png";
import sent from "../../../assets/sent.png";
import ButtonGroup from "../../Buttons/ButtonGroup";
import PopUpMessage from "./PopUpMessage";
import rejectIcon from "../../../assets/rejected.png";
import resolvedIcon from "../../../assets/resolvedIcon.png";
import delIcon from "../../../assets/del.png";

const ViewTicketDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  const messageEndRef = useRef(null); // Ref for scrolling to the last message

  const [messages, setMessages] = useState([
    {
      sender: consumerData.username || "Unknown User",
      text: "I need help with my account.",
      timestamp: "2024-12-16 10:00 AM",
    },
    {
      sender: "user",
      text: "Hello! How can I assist you?",
      timestamp: "2024-12-16 10:05 AM",
    },
  ]);

  const [newMessage, setNewMessage] = useState("");
  const [file, setFile] = useState(null);

  const handleSendMessage = () => {
    if (newMessage.trim() || file) {
      const message = {
        sender: "user",
        text: newMessage.trim(),
        file: file ? URL.createObjectURL(file) : null,
        timestamp: new Date().toLocaleString(),
      };

      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage("");
      setFile(null);
    }
  };

  // Scroll to the bottom whenever messages update
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupConfig, setPopupConfig] = useState({
    title: "",
    message: "",
    action: null,
  });

  const handleActionClick = (action) => {
    const config = {
      Resolved: {
        title: "Mark As Resolved",
        message: "Are you sure you want to mark this query as resolved?",
        action: () => handleAction("Resolved"),
        icon: resolvedIcon,
      },
      Reject: {
        title: "Mark As Rejected",
        message: "Are you sure you want to mark this query as rejected?",
        action: () => handleAction("Rejected"),
        icon: rejectIcon,
      },
      Delete: {
        title: "Delete Query",
        message: "Are you sure you want to delete this item?",
        action: () => handleAction("Deleted"),
        icon: delIcon,
      },
    };

    setPopupConfig(config[action]);
    setIsPopupOpen(true);
  };

  const handleAction = (status) => {
    setIsPopupOpen(false);
    console.log(`Query marked as ${status}`);
    // Implement your API call or logic here
  };

  if (!consumerData || !Object.keys(consumerData).length) {
    return <div>No ticket details available.</div>;
  }

  const additionalFields = [
    { label: "Title of Query", name: "name", value: consumerData.name },
    { label: "Ticket ID", name: "ticketid", value: consumerData.ticketid },
    { label: "Date", name: "date", value: consumerData.Date },
    { label: "User Full Name", name: "username", value: consumerData.username },
    { label: "User Mobile No.", name: "phone", value: consumerData.phone },
    { label: "User E-mail", name: "email", value: consumerData.email },
  ];

  return (
    <div>
      <div className="flex justify-between items-center self-center">
        <Title
          title={`${consumerData.name || "Ticket Details"}`}
          goBack={() => navigate(-1)}
        />
        <div className="flex gap-6 items-center">
          <button className="text-[#344054] border text-sm font-lato font-normal h-10 px-5 rounded-lg border-[#D0D5DD] flex items-center justify-center">
            Export
          </button>
          <span
            className={` ${consumerData?.statusColor} text-black font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center`}
          >
            {consumerData?.status}
          </span>
        </div>
      </div>

      <div className="mt-9 bg-white rounded-2xl mb-8 p-8">
        <div className="grid grid-cols-2 w-4/5 gap-x-10 gap-y-3 mb-3">
          {additionalFields.map((field) => (
            <div key={field.name}>
              <label className="text-sm font-semibold text-[#606060] font-nunito">
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                value={field.value}
                placeholder="Enter here"
                className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
              />
            </div>
          ))}
        </div>

        {/* Chat Section */}
        <div className="mt-5">
          <div
            style={{
              overflowY: "auto",
              height: "16rem",
              padding: "1rem",
              scrollbarWidth: "thin",
              scrollbarColor: "#08875D #f1f1f1",
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`mb-4 flex ${
                  msg.sender === "user" ? "justify-start" : "justify-start"
                }`}
              >
                <div className="flex items-start gap-3">
                  <img
                    src={msg.sender === "user" ? adminImg : profileImg}
                    alt={msg.sender}
                    className="w-8 h-8 rounded-full"
                  />
                  <div className="flex">
                    <div className="flex flex-col">
                      <p className="text-sm font-semibold">
                        {msg.sender === "user"
                          ? "You"
                          : consumerData.username || "Admin"}
                      </p>
                      <span className="text-xs text-gray-500">
                        {msg.timestamp}
                      </span>
                    </div>
                    <div
                      className={`p-3 ml-7 mt-2 rounded-lg shadow-md w-[600px] ${
                        msg.sender === "user"
                          ? "bg-gray-100 text-black font-nunito text-sm font-normal"
                          : "bg-gray-100 text-black font-nunito text-sm font-normal"
                      }`}
                    >
                      <p>{msg.text}</p>
                      {msg.file && (
                        <img
                          src={msg.file}
                          alt="attachment"
                          className="mt-2 w-32 rounded"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={messageEndRef} /> {/* Reference for scrolling */}
          </div>

          <div className="mt-4 flex items-center gap-3 relative">
            <input
              type="text"
              placeholder="Type message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleKeyDown} // Add this line
              className="flex-grow border p-2 rounded pr-10"
            />

            {file && (
              <div className="absolute top-1/2 transform -translate-y-1/2 right-[80px] flex items-center gap-2">
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  className="w-10 h-10 rounded border object-cover"
                />
                <button
                  onClick={() => setFile(null)}
                  className="text-red-500 text-xs font-bold"
                >
                  ×
                </button>
              </div>
            )}

            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              className="hidden"
              id="file-upload"
            />
            <label htmlFor="file-upload" className="cursor-pointer">
              <img src={attach} alt="Attach" className="w-5 h-5" />
            </label>

            <button onClick={handleSendMessage} className="cursor-pointer">
              <img src={sent} alt="Send" className="w-6 h-6" />
            </button>
          </div>

          <div className="mt-16 mb-8 px-8">
            <ButtonGroup
              buttons={[
                {
                  label: "Delete",
                  variant: "discard",
                  onClick: () => handleActionClick("Delete"),
                },
                {
                  label: "Reject",
                  variant: "delete",
                  onClick: () => handleActionClick("Reject"),
                  disabled: consumerData?.status === "Resolved",
                },
                {
                  label: "Resolved",
                  variant: "save",
                  onClick: () => handleActionClick("Resolved"),
                  disabled: consumerData?.status === "Rejected",
                },
              ]}
            />
          </div>
          <PopUpMessage
            isOpen={isPopupOpen}
            icon={popupConfig.icon}
            title={popupConfig.title}
            message={popupConfig.message}
            onCancel={() => setIsPopupOpen(false)}
            onConfirm={popupConfig.action}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTicketDetail;
