import React from "react";

const ActivityLog = () => {
  // Sample data array with combined data in the description field
  const logData = [
    {
      description:
        "User Successfully Logged In [User Id: 1, Is Staff Member: Yes, IP: 117.97.166.225]",
      date: "18-11-2024",
      time: "2:32 PM",
    },
    {
      description:
        "User Successfully Logged In [User Id: 2, Is Staff Member: Yes, IP: 117.97.166.225]",
      date: "18-11-2024",
      time: "3:15 PM",
    },
    {
      description:
        "User Successfully Logged In [User Id: 3, Is Staff Member: Yes, IP: 117.97.166.225]",
      date: "18-11-2024",
      time: "4:10 PM",
    },
    {
      description:
        "User Successfully Logged In [User Id: 4, Is Staff Member: Yes, IP: 117.97.166.225]",
      date: "18-11-2024",
      time: "5:00 PM",
    },
  ];

  // Function to parse description string and return structured data
  const parseDescription = (description) => {
    const regex = /\[User Id: (\d+), Is Staff Member: (\w+), IP: ([\d.]+)\]/;
    const match = description.match(regex);
    return {
      isStaff: match ? match[2] : "",
    };
  };

  return (
    <div className="container mx-auto ">
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse ">
          <thead>
            <tr className="bg-white rounded-2xl text-[#202224] text-sm font-nunito font-semibold text-left">
              <th className=" px-4 py-4">Description</th>
              <th className=" px-4 py-4">Date</th>
              <th className=" px-4 py-4">Staff </th>
            </tr>
          </thead>
          <tbody>
            {logData.map((log, index) => {
              const { isStaff } = parseDescription(log.description);
              return (
                <tr
                  key={index}
                  className={
                    "bg-gray-50 text-[#878787] text-sm font-semibold font-nunito"
                  }
                >
                  <td className="border-b border-gray-300 px-4 py-2">
                    {log.description}
                  </td>
                  <td className="border-b border-gray-300 px-4 py-2">{`${log.date} ${log.time}`}</td>
                  <td className="border-b border-gray-300 px-4 py-2">
                    {isStaff}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivityLog;
