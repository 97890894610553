import React, { useState } from "react";
import UploadButton from "../../Buttons/UploadButton";
import UploadProfilePic from "../../Buttons/UploadProfilePic";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";

function ProfilePage() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [defaultLanguage, setDefaultLanguage] = useState("");
  const [twoFactorAuth, setTwoFactorAuth] = useState("Disabled");

  const handleSave = () => {
    console.log({
      firstName,
      lastName,
      email,
      phone,
      defaultLanguage,
      twoFactorAuth,
    });
    alert("Profile saved successfully!");
  };

  const handlePasswordChange = () => {
    navigate("/change-password");
  };

  return (
    <div className="bg-gray-50 min-h-screen py-8 px-4 flex justify-center items-center">
      <div className="bg-white w-full max-w-4xl p-8 rounded-lg shadow-md flex space-x-8">
        {/* Profile Details Section */}
        <div className="w-2/3">
          <h1 className="text-2xl font-bold text-[#202224] font-nunito mb-6">
            My Profile
          </h1>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-3 text-gray-600">
                First Name *
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm mb-3 font-medium text-gray-600">
                Last Name *
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div>
              <label className="block mb-3 text-sm font-medium text-gray-600">
                Email *
              </label>
              <input
                type="email"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm mb-3 font-medium text-gray-600">
                Phone Number
              </label>
              <input
                type="text"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm mb-3 font-medium text-gray-600">
                Default Language
              </label>
              <select
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                value={defaultLanguage}
                onChange={(e) => setDefaultLanguage(e.target.value)}
              >
                <option value="">Select</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Marathi">Marathi</option>
                <option value="Telugu">Telugu</option>
                <option value="Malayalam">Malayalam</option>
              </select>
            </div>

            <div className="py-16">
              <ButtonGroup
                buttons={[
                  { label: "Discard", variant: "delete" },
                  { label: "Save", variant: "save" },
                ]}
              />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-black mb-2">
                Two-Factor Authentication
              </h2>
              <div className="space-y-2 border bg-[#F5F6FA] border-gray-300 rounded-xl p-2">
                {[
                  "Disabled",
                  "Enable Email Two-Factor Authentication",
                  "Enable WhatsApp Two-Factor Authentication",
                ].map((option) => (
                  <label key={option} className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="twoFactorAuth"
                      value={option}
                      checked={twoFactorAuth === option}
                      onChange={(e) => setTwoFactorAuth(e.target.value)}
                      className="form-radio h-4 w-4 text-green-600 focus:ring-green-500"
                    />
                    <span className="text-base font-lato p-2 text-black">
                      {option}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            <div className="pt-6">
              <ButtonGroup
                buttons={[
                  { label: "Save", variant: "save", onClick: handleSave },
                ]}
              />
            </div>
          </div>
        </div>

        {/* Profile Picture Section */}
        <div className="flex flex-col items-center w-1/3">
          {/* <div className="w-32 h-32 rounded-full bg-gray-200 overflow-hidden flex items-center justify-center mb-4">
            <span className="text-gray-500">Profile Pic</span>
          </div>
          <button
            className="text-sm text-green-600 hover:text-green-800 underline focus:outline-none"
            onClick={handleProfilePicChange}
          >
            Change Profile Picture
          </button>
          <button
            className="mt-6 px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none"
            onClick={handleChangePassword}
          >
            Change Password
          </button> */}

          <div className="col-span-1 flex flex-col items-center">
            <UploadProfilePic />
            <UploadButton
              label="Change Password"
              handleUpload={handlePasswordChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
