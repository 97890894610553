const roleData = [
  {
    sn: 1,
    name: "Admin",
    totalUsers: 10,
    users: [
      { id: 1, name: "John Doe", role: "Admin" },
      { id: 2, name: "Jane Smith", role: "Admin" },
      { id: 3, name: "Alice Johnson", role: "Admin" },
    ],
  },
  {
    sn: 2,
    name: "Editor",
    totalUsers: 15,
    users: [
      { id: 1, name: "John Doe", role: "Editor" },
      { id: 2, name: "Jane Smith", role: "Editor" },
      { id: 3, name: "Alice Johnson", role: "Editor" },
    ],
  },
  {
    sn: 3,
    name: "Viewer",
    totalUsers: 30,
    users: [
      { id: 1, name: "John Doe", role: "Viewer" },
      { id: 2, name: "Jane Smith", role: "Viewer" },
      { id: 3, name: "Alice Johnson", role: "Viewer" },
    ],
  },
  {
    sn: 4,
    name: "Contributor",
    totalUsers: 5,
    users: [
      { id: 1, name: "John Doe", role: "Contributor" },
      { id: 2, name: "Jane Smith", role: "Contributor" },
      { id: 3, name: "Alice Johnson", role: "Contributor" },
    ],
  },
  {
    sn: 5,
    name: "Moderator",
    totalUsers: 7,
    users: [
      { id: 1, name: "John Doe", role: "Moderator" },
      { id: 2, name: "Jane Smith", role: "Moderator" },
      { id: 3, name: "Alice Johnson", role: "Moderator" },
    ],
  },
];

export default roleData;
