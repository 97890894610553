import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import UploadButton from "../../Buttons/UploadButton";
import ButtonGroup from "../../Buttons/ButtonGroup";

const AddNewRole = () => {
  const navigate = useNavigate();
  const handleLogCredentials = () => {
    navigate("/add-log-credential");
  };

  const features = [
    {
      name: "Bulk PDF Export",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },
    {
      name: "Invoices",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },
    {
      name: "Settings",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },
    {
      name: "Consumer Management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Services Management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Service provider management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Finance Management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Accounts",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Payments",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Reports (help& support)",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Content Management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Notification management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },

    {
      name: "Roles Management",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },
  ];
  const customScrollbarStyle = {
    scrollbarWidth: "thin", // For Firefox
    scrollbarColor: "#38a169 #e2e8f0", // Thumb and track colors for Firefox
    overflowY: "auto",
    maxHeight: "30rem",
    border: "1px solid #e2e8f0",
    borderRadius: "8px",
  };
  return (
    <div className="relative min-h-screen font-sans">
      {/* Page Header */}
      <div className="flex items-center mb-8">
        <Title title="Role Management" goBack={() => navigate(-1)} />
      </div>

      {/* Main Content */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        {/* Role Name Input with Login Credentials */}
        <div className="flex items-center justify-between mb-6">
          <div className="w-2/3 mr-4">
            <label className="block font-semibold text-gray-700 mb-2">
              Role Name
            </label>
            <input
              type="text"
              placeholder="Enter Here"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>
          <div className="w-64">
            <UploadButton
              label="Login Credentials"
              handleUpload={handleLogCredentials}
            />
          </div>
        </div>

        {/* Features and Permissions */}
        <div className="grid grid-cols-3 font-semibold text-gray-600 mb-4">
          <span>Features</span>
          <span className="col-span-2">Permissions</span>
        </div>

        <div className=" w-2/3 max-h-[30rem] border rounded-md border-gray-200">
          <div style={customScrollbarStyle} className="custom-scrollbar">
            {features.map((feature, index) => (
              <div
                key={index}
                className="grid grid-cols-2 gap-4 mb-4 p-4 border-b last:border-none"
              >
                <div>
                  <span className="bg-[#B5E4CA] text-[#202224] font-sm font-nunito font-semibold px-4 py-2 rounded-md">
                    {feature.name}
                  </span>
                </div>

                {/* Permissions */}
                <div className="grid grid-cols-1 gap-2 p-2">
                  {feature.permissions.map((perm, i) => (
                    <label
                      key={i}
                      className="flex items-center space-x-2 font-lato text-base"
                    >
                      <input
                        type="radio"
                        name={`${feature.name}-perm`}
                        className="accent-green-500"
                      />
                      <span>{perm}</span>
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-16 mb-8 px-8">
          <ButtonGroup
            buttons={[
              { label: "Discard", variant: "delete" },
              { label: "Save", variant: "save" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewRole;
