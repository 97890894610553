const notificationData = [
  {
    id: 1,
    name: "John Doe - Your account has been verified successfully.",
    date: "2023-12-13",
    time: "10:00 AM",
    description:
      "Your account has been successfully verified. You can now access all features.",
  },
  {
    id: 2,
    name: "Jane Smith - Your account verification is pending.",
    date: "2023-12-13",
    time: "11:00 AM",
    description:
      "We are currently reviewing your account verification. Please wait for confirmation.",
  },
  {
    id: 3,
    name: "Mike Johnson - Your account verification has been rejected.",
    date: "2023-12-13",
    time: "12:00 PM",
    description:
      "Unfortunately, your account verification has been rejected. Please review the verification details and try again.",
  },
  {
    id: 4,
    name: "Alice Brown - Your account has been verified successfully.",
    date: "2023-12-13",
    time: "01:00 PM",
    description:
      "Your account has been successfully verified. Enjoy exploring all the available features.",
  },
];

export default notificationData;
