import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../../../Title/Title";
import { totalData } from "../../../../data/content/basicpay";
import ButtonGroup from "../../../../Buttons/ButtonGroup";

const AddNewPay = () => {
  const navigate = useNavigate();

  const [selectedState, setSelectedState] = useState("");
  const [currentStateData, setCurrentStateData] = useState(null);

  const allStates = totalData.states.map((state) => state.state);

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setSelectedState(newState);
    const newStateData = totalData.states.find(
      (state) => state.state === newState
    );
    setCurrentStateData(newStateData);
  };

  // Extract all unique service categories across all states
  const allServiceCategories = [
    ...new Set(
      totalData.states.flatMap((state) =>
        state.services.map((service) => service.serviceCategory)
      )
    ),
  ];

  const displayedServices =
    currentStateData?.services.length > 0
      ? currentStateData.services
      : allServiceCategories.map((category) => ({ serviceCategory: category }));

  return (
    <div>
      <div className="flex gap-8">
        <div>
          <Title title="Add New Basic Pay" goBack={() => navigate(-1)} />
        </div>
      </div>

      <div className="bg-white rounded-2xl h-screen p-8 mt-8">
        <div className="">
          <label
            htmlFor="stateSelector"
            className="block text-sm mb-4 font-medium text-gray-700"
          >
            Select Location
          </label>
          <select
            id="stateSelector"
            className="mt-2 block w-4/5 p-2 border rounded-lg"
            value={selectedState}
            onChange={handleStateChange}
          >
            <option value="" disabled>
              Select a state
            </option>
            {allStates.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-6 mb-8">
          <div className="mt-4">
            <table className="table-auto w-full border-collapse">
              <thead>
                <tr>
                  <th className="text-[#606060] text-sm font-nunito font-semibold px-4 py-2 text-left">
                    Service Category
                  </th>
                  <th className="px-4 py-2 text-left text-[#606060] text-sm font-nunito font-semibold">
                    Select Parameter
                  </th>
                  <th className="px-4 py-2 text-left text-[#606060] text-sm font-nunito font-semibold">
                    Charges Per Month
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayedServices.map((service, serviceIndex) => (
                  <tr key={serviceIndex}>
                    <td className="font-lato text-sm font-normal mb-4 px-4 py-2">
                      <div className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4">
                        {service.serviceCategory}
                      </div>
                    </td>
                    <td className="font-lato text-sm font-normal px-4 py-2">
                      <div className="bg-[#F5F6FA] rounded p-4 border border-[#D5D5D5]">
                        <select className="font-lato bg-[#F5F6FA] rounded text-sm font-normal">
                          <option value="">Select Parameter</option>
                          {/* Add dynamic options if needed */}
                        </select>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="border p-4 rounded bg-[#F5F6FA] border-[#D5D5D5]">
                        <input
                          type="text"
                          className="w-full bg-transparent"
                          placeholder="Enter charges"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex gap-6 font-lato md:text-sm font-normal capitalize mt-16">
          <ButtonGroup
            buttons={[
              {
                label: "Discard",
                variant: "delete",
                onClick: () => navigate(-1),
              },
              {
                label: "Save",
                variant: "save",
                onClick: () => alert("Saved"),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewPay;
