import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { totalData } from "../../../data/content/basicpay";
import { assessmentData } from "../../../data/content/assessmentData";
import Title from "../../../Title/Title";
import arrow from "../../../../assets/greenarrow.png";

const AssessmentsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = location.state || { userType: "Consumer" };
  const serviceCategories = Array.from(
    new Set(
      totalData.states.flatMap((state) =>
        state.services.map((service) => service.serviceCategory)
      )
    )
  );

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex gap-8">
          <div>
            <Title title="Assessments" goBack={() => navigate(-1)} />
          </div>
          <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
            {userType}
          </button>
        </div>
      </div>

      <div className="bg-white rounded-2xl p-8 mt-9 h-screen mb-8">
        <div>
          <p className="text-sm text-[#606060] font-semibold font-nunito">
            Service Category Wise Assessments
          </p>
        </div>

        <div className="mt-4">
          {serviceCategories.map((category, index) => (
            <div className="flex justify-between" key={index}>
              <div
                className="font-nunito flex justify-between font-normal w-4/5 bg-[#F5F6FA] text-black text-sm p-4 mb-2 rounded border-gray-300 cursor-pointer"
                onClick={() =>
                  navigate("/assessment-detail", {
                    state: { serviceCategory: category },
                  })
                }
              >
                <span>{category}</span>
                <img src={arrow} className="w-5 h-5" alt="Arrow" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssessmentsPage;
