import React, { useState } from "react";
import notify from "../../assets/bell.png";
import profile from "../../assets/profileIcon.png";
import dropdown from "../../assets/dropdown.png";
import rightArrow from "../../assets/rightArrow.png";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo.png";
function Navbar() {
  const navigate = useNavigate();
  const userName = "John Doe";
  const userRole = "Admin";
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  const notificationCount = 5; // Example count
  const languages = ["English", "Hindi", "Marathi", "Telugu", "Malyalam"];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (!isDropdownOpen) setIsLanguageDropdownOpen(false); // Close language dropdown if main dropdown closes
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleOptionClick = (callback) => {
    callback();
    setIsDropdownOpen(false); // Collapse dropdown
  };

  return (
    <div className="bg-white text-[#404040] p-4 pl-[67px] pr-8 fixed w-full top-0 z-10 flex justify-between items-center">
      {/* <h1 className="text-2xl text-[#08875D] font-extrabold">MaidEaze</h1> */}
      <img src={logo} className="w-[115px] " />

      <div className="flex items-center space-x-8 relative cursor-pointer">
        <div
          className="relative"
          onClick={() => navigate("/notification-list")}
        >
          <img
            src={notify}
            alt="Notification"
            className="w-6 h-6 cursor-pointer"
          />
          {notificationCount > 0 && (
            <span className="absolute -top-[8px] -right-[8px] bg-red-600 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
              {notificationCount}
            </span>
          )}
        </div>

        <img
          src={profile}
          alt="Profile"
          className="w-10 h-10 rounded-full bg-[#D8D8D8]"
        />

        <div className="text-sm text-left">
          <div className="text-base font-nunito font-bold text-[#404040]">
            {userName}
          </div>
          <div className="font-semibold text-sm font-nunito text-[#565656]">
            {userRole}
          </div>
        </div>

        <div>
          <img
            src={dropdown}
            alt="Dropdown"
            className="w-5 h-5 cursor-pointer"
            onClick={toggleDropdown}
          />
        </div>

        {isDropdownOpen && (
          <div className="absolute top-16 right-0 bg-white border rounded-xl shadow-lg w-48 text-sm z-20">
            <ul className="flex flex-col text[#202224] text-sm font-nunito font-semibold">
              <li
                className="px-4 py-3 hover:bg-[#B5E4CA] cursor-pointer"
                onClick={() => handleOptionClick(() => navigate("/profile"))}
              >
                My Profile
              </li>
              <hr />

              <li
                className="px-4 py-3 hover:bg-[#B5E4CA] cursor-pointer flex items-center justify-between relative"
                onClick={toggleLanguageDropdown}
              >
                <span>Language</span>
                <img
                  src={rightArrow}
                  className="w-3 h-3 object-contain"
                  alt="Right Arrow"
                />

                {isLanguageDropdownOpen && (
                  <ul className="absolute top-full right-0 mt-1 bg-white border rounded-xl shadow-lg w-40 z-30">
                    {languages.map((language) => (
                      <li
                        key={language}
                        className="hover:bg-[#B5E4CA] cursor-pointer"
                        onClick={() =>
                          handleOptionClick(() =>
                            console.log(`Selected language: ${language}`)
                          )
                        }
                      >
                        <p className="px-4 py-3 text-[#202224] font-nunito font-sm">
                          {language}
                        </p>
                        <hr />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <hr />
              <li
                className="px-4 py-3 hover:bg-[#B5E4CA] cursor-pointer"
                onClick={() => handleOptionClick(() => navigate("/logout"))}
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
