import React, { useState } from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";

const GenericTC = ({
  initialTitle = "Default Title",
  termsList = [{ label: "Terms and Conditions", content: "Default content." }],
  onSave = (data) => console.log("Save button clicked", data),
  goBack,
  showButtons = true, // New prop to control button group visibility
}) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(initialTitle);
  const [termsData, setTermsData] = useState(termsList);

  const handleEdit = () => {
    console.log("Edit button clicked");
  };

  const handleDelete = (index) => {
    const updatedTerms = termsData.map((term, i) =>
      i === index ? { ...term, content: "" } : term
    );
    setTermsData(updatedTerms);
    console.log(`Delete button clicked for item ${index}`);
    alert("Content has been cleared.");
  };

  const handleSave = () => {
    console.log("Save button clicked", { title, termsData });
    onSave({ title, termsData });
    alert("Content has been saved.");
  };

  const handleContentChange = (index, newContent) => {
    const updatedTerms = termsData.map((term, i) =>
      i === index ? { ...term, content: newContent } : term
    );
    setTermsData(updatedTerms);
  };

  return (
    <div>
      <div>
        <Title title={title} goBack={goBack || (() => navigate(-1))} />
      </div>
      <div className="bg-white rounded-2xl  mt-8 p-8 h-screen">
        {termsData.map((term, index) => (
          <div key={index} className="mb-8">
            <div className="flex flex-col">
              <label className="text-[#606060] font-semibold mb-3 font-nunito text-sm">
                {term.label}
              </label>
              <textarea
                rows="6"
                className="p-4 border rounded-md"
                value={term.content}
                onChange={(e) => handleContentChange(index, e.target.value)}
              ></textarea>
            </div>
          </div>
        ))}
        {showButtons && ( // Conditionally render the button group
          <div className="mt-16">
            <ButtonGroup
              buttons={[
                {
                  label: "Delete",
                  variant: "discard",
                  // onClick: () => handleDelete(index),
                },
                {
                  label: "Discard",
                  variant: "delete",
                  onClick: handleEdit,
                },
                {
                  label: "Save",
                  variant: "save",
                  onClick: handleSave,
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GenericTC;
