import React from "react";
import Title from "../../../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../../../../../assets/greenarrow.png";
import { totalData } from "../../../../data/content/basicpay";

const BasicPay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = location.state || { userType: "Consumer" };

  const handleAddBasicPay = () => {
    navigate("/add-basic-pay");
  };
  return (
    <div className="">
      <div className="flex justify-between">
        <div className="flex gap-8">
          <div>
            <Title title="Basic Pays" goBack={() => navigate(-1)} />
          </div>
          <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
            {userType}
          </button>
        </div>

        <button
          onClick={handleAddBasicPay}
          className="rounded-[8px] bg-green  md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
        >
          + Add New Basic Pay
        </button>
      </div>

      {/* Content Section */}
      <div className="bg-white rounded-2xl p-8 mt-9 h-screen mb-8">
        <div>
          <p className="text-sm text-[#606060] font-semibold font-nunito">
            Basic Service Charges in different locations
          </p>
        </div>

        {/* Display States */}
        <div className="mt-4">
          {totalData.states.map((stateData, index) => (
            <div className="flex justify-between" key={index}>
              <div
                className="font-nunito flex justify-between font-normal w-4/5 bg-[#F5F6FA] text-black text-sm p-4 mb-2 rounded border-gray-300 cursor-pointer"
                onClick={() =>
                  navigate("/basic-pay-detail", {
                    state: { stateData, userType },
                  })
                }
              >
                <span>{stateData.state}</span>
                <img src={arrow} className="w-5 h-5" alt="Arrow" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BasicPay;
