import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";

const SendNotificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userType = location.state?.userType || "Unknown";

  return (
    <div>
      {/* Header Section */}
      <div className="flex gap-8 items-center mb-6">
        <Title title="Send New Notification" goBack={() => navigate(-1)} />
        <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
          {userType}
        </button>
      </div>

      {/* Notification Form */}
      <div className="bg-white p-6 rounded-lg shadow-md ">
        <form className="space-y-6 w-4/5">
          {/* Notification Title */}
          <div>
            <label
              htmlFor="title"
              className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
            >
              Notification Title
            </label>
            <input
              type="text"
              id="title"
              className="w-full border border-gray-300 rounded-lg p-3"
              placeholder="Enter Here"
            />
          </div>

          {/* Select User */}
          <div>
            <label
              htmlFor="user"
              className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
            >
              Select User
            </label>
            <select
              id="user"
              className="w-full border border-gray-300 rounded-lg p-3"
            >
              <option value="">Select</option>
              <option value="user1">User 1</option>
              <option value="user2">User 2</option>
            </select>
          </div>

          {/* Notification Description */}
          <div>
            <label
              htmlFor="description"
              className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
            >
              Notification Description
            </label>
            <textarea
              id="description"
              rows="4"
              className="w-full border border-gray-300 rounded-lg p-3"
              placeholder="Write description"
            ></textarea>
          </div>

          <div className="pb-16">
            <label
              htmlFor="datetime"
              className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
            >
              Notification Push Time & Date
            </label>
            <input
              type="datetime-local"
              id="datetime"
              className="w-full border border-gray-300 rounded-lg p-3"
            />
          </div>

          <div>
            <ButtonGroup
              buttons={[
                {
                  label: "Discard",
                  variant: "delete",
                  onClick: () => navigate(-1),
                },
                {
                  label: "Send",
                  variant: "save",
                  onClick: () => alert("Saved"),
                },
              ]}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendNotificationPage;
