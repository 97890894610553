import React from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import { totalRoleData } from "../../data/rolemanage/roleManageStat";
import GenericTable from "../../GenericTable/GenericTable";
import userData from "../../data/rolemanage/roleTable";

const RoleManagementPage = () => {
  const navigate = useNavigate();
  const handleAddRoleClick = () => {
    navigate("/add-role");
  };

  const columns = [
    { header: "S.No.", key: "sn" },
    { header: "Role Name", key: "name" },
    { header: "Total Users", key: "totalUsers" },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <Title title="Role Management" goBack={() => navigate(-1)} />

        <button
          onClick={handleAddRoleClick}
          className="rounded-[8px] bg-green md:mb-6 md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
        >
          + Add New Role
        </button>
      </div>

      <div>
        <TotalStat data={totalRoleData} />
      </div>
      <div>
        <GenericTable
          title=""
          columns={columns}
          data={userData}
          redirectTo="/review-role"
          showFilters={true}
        />
      </div>
    </div>
  );
};

export default RoleManagementPage;
