import React from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";

const ChangePassword = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Title title="Change Password" goBack={() => navigate(-1)} />
      </div>
      <div>
        <div className="bg-white p-6 rounded-lg shadow-md mt-8 ">
          <form className="space-y-6 w-4/5">
            <div>
              <label
                htmlFor="oldpass"
                className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
              >
                Old Password
              </label>
              <input
                type="text"
                id="oldpass"
                className="w-full border border-gray-300 rounded-lg p-3"
                placeholder="Enter Here"
              />
            </div>

            <div>
              <label
                htmlFor="newpass"
                className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
              >
                New Password
              </label>
              <input
                type="text"
                id="newpass"
                className="w-full border border-gray-300 rounded-lg p-3"
                placeholder="Enter Here"
              />
            </div>
            <div>
              <label
                htmlFor="repass"
                className="block text-[#606060] font-nunito text-sm font-semibold mb-2"
              >
                Re-Enter New Password
              </label>
              <input
                type="text"
                id="repass"
                className="w-full border border-gray-300 rounded-lg p-3"
                placeholder="Enter Here"
              />
            </div>

            <div className="pt-16">
              <ButtonGroup
                buttons={[
                  {
                    label: "Discard",
                    variant: "delete",
                    onClick: () => navigate(-1),
                  },
                  {
                    label: "Send",
                    variant: "save",
                    onClick: () => alert("Saved"),
                  },
                ]}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
