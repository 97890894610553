import React from "react";
import { useNavigate } from "react-router-dom";
import GenericTC from "../GenericTc";

const ServiceGuidelines = () => {
  const navigate = useNavigate();

  // Updated data structure to support multiple terms and labels
  const serviceGuidelinesData = {
    title: "Service Guidelines",
    termsAndConditions: [
      {
        label: "Service Guidelines Body Content",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  };

  const handleSave = (data) => {
    console.log("Service Guidelines-specific save logic", data);
  };

  return (
    <GenericTC
      initialTitle={serviceGuidelinesData.title}
      termsList={serviceGuidelinesData.termsAndConditions} // Pass the array of terms
      onSave={handleSave}
      goBack={() => navigate(-1)}
    />
  );
};

export default ServiceGuidelines;
