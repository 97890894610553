// assessmentData.js
export const assessmentData = {
  instructions:
    "Please answer the following questions based on the service category selected. Mark the correct answer for each question.",
  services: {
    Cleaning: [
      {
        question:
          "What is the most effective cleaning solution for hardwood floors?",
        options: ["Water", "Vinegar", "Chemical Cleaner", "None"],
        correctAnswer: "Vinegar",
      },
      {
        question: "How often should carpets be professionally cleaned?",
        options: ["Every 6 months", "Once a year", "Every 2 years", "Never"],
        correctAnswer: "Every 6 months",
      },
    ],
    Laundry: [
      {
        question: "What is the ideal temperature for washing delicates?",
        options: ["Cold", "Warm", "Hot", "Boiling"],
        correctAnswer: "Cold",
      },
      {
        question: "What is the best way to dry woolen clothes?",
        options: ["Tumble dry", "Air dry flat", "Hang dry", "Dry clean only"],
        correctAnswer: "Air dry flat",
      },
    ],
    Gardening: [
      {
        question: "What is the ideal temperature for washing delicates?",
        options: ["Cold", "Warm", "Hot", "Boiling"],
        correctAnswer: "Cold",
      },
      {
        question: "What is the best way to dry woolen clothes?",
        options: ["Tumble dry", "Air dry flat", "Hang dry", "Dry clean only"],
        correctAnswer: "Air dry flat",
      },
    ],

    Cooking: [
      {
        question: "What is the ideal temperature for washing delicates?",
        options: ["Cold", "Warm", "Hot", "Boiling"],
        correctAnswer: "Cold",
      },
      {
        question: "What is the best way to dry woolen clothes?",
        options: ["Tumble dry", "Air dry flat", "Hang dry", "Dry clean only"],
        correctAnswer: "Air dry flat",
      },
    ],
  },
};
