import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";

const ReviewNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType, notificationTitle } = location.state || {
    userType: "Consumer",
    notificationTitle: "",
  };

  const handleGoBack = () => {
    navigate(-1, {
      state: {
        userType,
        notificationTitle,
      },
    });
  };

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <Title
          title={notificationTitle || "Notification Title"}
          goBack={handleGoBack}
        />
        <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
          {userType}
        </button>
      </div>

      {/* Form Section */}
      <div className="bg-white rounded-2xl p-8 mt-4 shadow-lg ">
        <form className="space-y-6 mb-52">
          {/* Notification Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notification Title
            </label>
            <input
              type="text"
              defaultValue={notificationTitle}
              className="block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
            />
          </div>

          {/* Select User Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select User
            </label>
            <select className="block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black">
              <option>All Users</option>
              <option>Consumer</option>
              <option>Service Provider</option>
            </select>
          </div>

          {/* Notification Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notification Description
            </label>
            <textarea
              rows="4"
              className="block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
              defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            ></textarea>
          </div>

          {/* Notification Push Date & Time */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notification Push Time & Date
            </label>
            <input
              type="datetime-local"
              defaultValue="2024-10-10T00:31"
              className="block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviewNotification;
