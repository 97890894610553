import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Title from "../../../../Title/Title";
import { totalData } from "../../../../data/content/basicpay";
import ButtonGroup from "../../../../Buttons/ButtonGroup";

const BasicPayDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stateData, userType } = location.state || {};

  const [selectedState, setSelectedState] = useState(stateData?.state || "");
  const [currentStateData, setCurrentStateData] = useState(stateData);
  const [isEditing, setIsEditing] = useState(false);

  const allStates = totalData.states.map((state) => state.state);

  // Handle state change
  const handleStateChange = (event) => {
    const newState = event.target.value;
    setSelectedState(newState);
    const newStateData = totalData.states.find(
      (state) => state.state === newState
    );
    setCurrentStateData(newStateData);
  };

  // Toggle Edit Mode
  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  // Save Changes
  const saveChanges = () => {
    // Logic to save changes (e.g., updating the backend)
    console.log("Saved data:", currentStateData);
    setIsEditing(false);
  };

  // Handle changes for dropdown and text inputs
  const handleParameterChange = (serviceIndex, parameterIndex, key, value) => {
    const updatedServices = currentStateData.services.map((service, sIndex) =>
      sIndex === serviceIndex
        ? {
            ...service,
            parameters: service.parameters.map((parameter, pIndex) =>
              pIndex === parameterIndex
                ? { ...parameter, [key]: value }
                : parameter
            ),
          }
        : service
    );

    setCurrentStateData({ ...currentStateData, services: updatedServices });
  };

  return (
    <div>
      {/* Header Section */}
      <div className="flex gap-8">
        <div>
          <Title title="Basic Pay Details" goBack={() => navigate(-1)} />
        </div>
        <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
          {userType || "Unknown User"}
        </button>
      </div>

      {/* Location Selector */}
      <div className="bg-white rounded-2xl h-screen p-8 mt-8">
        <div>
          <label
            htmlFor="stateSelector"
            className="block text-sm mb-4 font-medium text-gray-700"
          >
            Select Location
          </label>
          <select
            id="stateSelector"
            className="mt-2 block w-4/5 p-2 border rounded-lg"
            value={selectedState}
            onChange={handleStateChange}
          >
            <option value="" disabled>
              Select a state
            </option>
            {allStates.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-6 mb-8">
          {currentStateData ? (
            <div className="mt-4">
              <table className="table-auto w-full border-collapse">
                <thead>
                  <tr>
                    <th className="text-[#606060] text-sm font-nunito font-semibold px-4 py-2 text-left">
                      Service Category
                    </th>
                    <th className="px-4 py-2 text-left text-[#606060] text-sm font-nunito font-semibold">
                      Select Parameter
                    </th>
                    <th className="px-4 py-2 text-left text-[#606060] text-sm font-nunito font-semibold">
                      Charges Per Month
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentStateData.services.map((service, serviceIndex) =>
                    service.parameters.map((parameter, parameterIndex) => (
                      <tr key={`${serviceIndex}-${parameterIndex}`}>
                        <td className="font-lato text-sm font-normal mb-4 px-4 py-2">
                          <div className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4">
                            {service.serviceCategory}
                          </div>
                        </td>
                        <td className="font-lato text-sm font-normal px-4 py-2">
                          <div className="bg-[#F5F6FA] rounded p-4 border border-[#D5D5D5]">
                            <select
                              className="font-lato bg-[#F5F6FA] rounded text-sm font-normal p-2 w-full border border-gray-300"
                              value={parameter.selectParameter}
                              onChange={(e) =>
                                handleParameterChange(
                                  serviceIndex,
                                  parameterIndex,
                                  "selectParameter",
                                  e.target.value
                                )
                              }
                              disabled={!isEditing}
                            >
                              {service.parameters.map((option, optionIndex) => (
                                <option
                                  key={optionIndex}
                                  value={option.selectParameter}
                                >
                                  {option.selectParameter}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                        <td className="px-4 py-2">
                          <div className="border p-4 rounded bg-[#F5F6FA] border-[#D5D5D5]">
                            {isEditing ? (
                              <input
                                type="text"
                                className="font-lato bg-[#F5F6FA] rounded text-sm font-normal p-2 border border-gray-300 w-full"
                                value={parameter.chargesPerMonth}
                                onChange={(e) =>
                                  handleParameterChange(
                                    serviceIndex,
                                    parameterIndex,
                                    "chargesPerMonth",
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              `₹ ${parameter.chargesPerMonth}`
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available for the selected state.</p>
          )}
        </div>

        <div className="flex gap-6 font-lato md:text-sm font-normal capitalize mt-16">
          <ButtonGroup
            buttons={[
              {
                label: "Discard",
                variant: "discard",
                onClick: () => setIsEditing(false),
              },
              { label: "Edit", variant: "delete", onClick: toggleEdit },
              { label: "Save", variant: "save", onClick: saveChanges },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicPayDetail;
