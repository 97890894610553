import React, { useState } from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import { totalTickets as consumerTickets } from "../../data/helpsupport/totalTickets";
import { totalTickets as serviceProviderTickets } from "../../data/helpsupport/totalTickets";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import consumerData from "../../data/helpsupport/ticketTable";
import serviceProviderData from "../../data/helpsupport/ticketTable";
import GenericTable from "../../GenericTable/GenericTable";

const HelpSuppportPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("consumer");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const consumerColumns = [
    { header: "S.No.", key: "SNo" },
    { header: "Query Title", key: "name" },
    { header: "Date", key: "Date" },
    { header: "Status.", key: "status" },
  ];

  const serviceProviderColumns = [
    { header: "S.No.", key: "SNo" },
    { header: "Service Type", key: "name" },
    { header: "Date", key: "Date" },
    { header: "Status.", key: "status" },
  ];

  return (
    <div>
      <div className="flex flex-row gap-8">
        <Title title="Help & Support" goBack={() => navigate(-1)} />

        <div
          className={`rounded-lg ${
            activeTab === "consumer"
              ? "bg-black text-white"
              : "bg-white border border-black text-black"
          } font-lato item-center`}
        >
          <button
            className="px-10 text-sm py-[10px] font-bold rounded-lg"
            onClick={() => handleTabChange("consumer")}
          >
            CONSUMER
          </button>
        </div>

        <div
          className={`rounded-lg ${
            activeTab === "serviceProvider"
              ? "bg-black text-white"
              : "bg-white border border-black text-black"
          } font-lato item-center`}
        >
          <button
            className="px-10 text-sm py-[10px] font-bold rounded-lg"
            onClick={() => handleTabChange("serviceProvider")}
          >
            SERVICE PROVIDER
          </button>
        </div>
      </div>

      <div className="mt-9">
        {activeTab === "consumer" ? (
          <>
            <TotalStat data={consumerTickets} />
            <GenericTable
              title=""
              columns={consumerColumns}
              data={consumerData}
              redirectTo="/viewticket-detail"
              showFilters={true}
            />
          </>
        ) : (
          <>
            <TotalStat data={serviceProviderTickets} />
            <GenericTable
              title=""
              columns={serviceProviderColumns}
              data={serviceProviderData}
              redirectTo="/viewticket-detail"
              showFilters={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HelpSuppportPage;
