import React from "react";
import loginImage from "../../../assets/image.png";

const Login = () => {
  return (
    <div className="flex h-screen items-center justify-center ">
      <div className="flex w-full max-w-4xl mr-16 bg-white  rounded-lg overflow-hidden">
        <div className="w-1/2 p-6 flex justify-center items-center">
          <img
            src={loginImage}
            alt="Login Illustration"
            className="object-contain max-h-96 h-[364px] w-[544px]"
          />
        </div>

        <div className="w-1/2 p-8 pt-14 border border-[#A6A6A6] rounded-3xl">
          <h2 className="text-2xl font-lato text-center font-bold text-gray-800 mb-4">
            Login to Account
          </h2>
          <p className="text-[#7B7B7B] opacity-80 mb-6">
            Please enter your email and password to continue
          </p>

          <form>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-sm font-semibold text-gray-600"
              >
                Username
              </label>
              <input
                type="email"
                id="username"
                className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
                placeholder="Your email"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-600"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
                placeholder="Your password"
              />
            </div>

            <div className="flex justify-between items-center mb-12">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="remember"
                  className="text-green-500 focus:ring-green-400 focus:outline-none focus:ring-2 focus:ring-offset-1"
                />
                <label
                  htmlFor="remember"
                  className="ml-2 text-sm text-gray-600"
                >
                  Remember Password
                </label>
              </div>

              <a href="#" className="text-sm text-green-500 hover:underline">
                Forgot Password?
              </a>
            </div>

            <button
              type="submit"
              className="w-full px-4 py-2 rounded-lg font-lato font-bold text-xl text-white bg-black mb-10 "
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
