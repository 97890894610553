import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { assessmentData } from "../../../data/content/assessmentData";
import ButtonGroup from "../../../Buttons/ButtonGroup";

const AssessmentDetailPage = () => {
  const location = useLocation();
  const { serviceCategory } = location.state;
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    console.log(serviceCategory);
    setQuestions(assessmentData.services[serviceCategory] || []);
  }, [serviceCategory]);

  const [newQuestion, setNewQuestion] = useState({
    question: "",
    options: ["", "", "", ""],
    correctAnswer: "",
  });

  const [isAdding, setIsAdding] = useState(false);

  const handleAddQuestion = () => {
    setQuestions([...questions, newQuestion]);
    setNewQuestion({
      question: "",
      options: ["", "", "", ""],
      correctAnswer: "",
    });
    setIsAdding(false); // Stop showing input fields
  };

  const handleNewQuestionChange = (e, field, index = null) => {
    if (field === "question") {
      setNewQuestion({ ...newQuestion, question: e.target.value });
    } else if (field === "options") {
      const updatedOptions = [...newQuestion.options];
      updatedOptions[index] = e.target.value;
      setNewQuestion({ ...newQuestion, options: updatedOptions });
    } else if (field === "correctAnswer") {
      setNewQuestion({ ...newQuestion, correctAnswer: e.target.value });
    }
  };

  return (
    <div className="bg-white rounded-2xl p-8 h-screen-max mb-8 mt-6 ">
      <div className="w-3/4 ">
        <div className="mb-9">
          <p className="text-sm text-[#606060] font-semibold font-nunito">
            Instructions
          </p>
          <p className="text-sm text-[#606060] border border-[#D5D5D5] rounded-md mt-3 bg-[#F5F6FA] p-4 font-semibold font-nunito">
            {assessmentData.instructions}
          </p>
        </div>
        {questions.length > 0 ? (
          questions.map((q, index) => (
            <div key={index} className="mb-6">
              <p className="text-sm text-[#606060] font-semibold font-nunito">
                {q.question}
              </p>
              <div className="mt-2 ">
                {q.options.map((option, idx) => (
                  <div
                    key={idx}
                    className="flex gap-4 bg-[#F5F6FA] mb-2 border p-2 rounded-md border-[#D5D5D5]"
                  >
                    <input
                      type="radio"
                      name={q.question}
                      value={option}
                      checked={option === q.correctAnswer}
                      readOnly
                    />
                    <div className=" ">{option}</div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No questions available for this service category.</p>
        )}
        <div className="">
          {isAdding && (
            <div className="mb-6">
              <label className="block text-sm font-semibold text-[#606060] mb-2">
                Question
              </label>
              <textarea
                value={newQuestion.question}
                onChange={(e) => handleNewQuestionChange(e, "question")}
                placeholder="Enter question"
                className="mb-4 w-full p-2 "
              />
              <label className="block text-sm font-semibold text-[#606060] mb-2">
                Options
              </label>
              {newQuestion.options.map((option, idx) => (
                <div
                  key={idx}
                  className="flex items-center gap-2 mb-2 border border-gray-300 rounded px-2"
                >
                  <input
                    type="radio"
                    name="correctAnswer"
                    value={option}
                    checked={option === newQuestion.correctAnswer}
                    onChange={(e) =>
                      handleNewQuestionChange(e, "correctAnswer")
                    }
                  />
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleNewQuestionChange(e, "options", idx)}
                    placeholder={`Option ${idx + 1}`}
                    className="w-full p-2"
                  />
                </div>
              ))}
              <button
                onClick={handleAddQuestion}
                className="bg-green-500 text-white py-2 px-6 rounded-lg mt-4"
              >
                Save Question
              </button>
            </div>
          )}
          <button
            onClick={() => setIsAdding(true)}
            className="text-green border mt-9 mb-24 border-green border-dashed  py-2 px-6 rounded-lg "
          >
            + Add Question
          </button>
        </div>

        <div className="pb-16">
          <ButtonGroup
            buttons={[
              {
                label: "Delete",
                variant: "discard",
                // onClick: () => handleDelete(index),
              },
              {
                label: "Discard",
                variant: "delete",
                //   onClick: handleEdit,
              },
              {
                label: "Save",
                variant: "save",
                //   onClick: handleSave,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentDetailPage;
