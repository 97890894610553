import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Dashboard/MainDashboard/Home";

import ConsumerPage from "./components/Dashboard/Consumer/ConsumerPage";
import AddNewConsumer from "./components/Dashboard/Consumer/AddNewConsumer";
import UploadDocument from "./components/Dashboard/Consumer/UploadDocument";
import ConsumerDetails from "./components/Dashboard/Consumer/ConsumerDetails";
import ServiceProviderPage from "./components/Dashboard/ServiceProvider/ServiceProviderPage";
import AddNewServiceProvider from "./components/Dashboard/ServiceProvider/AddNewServiceProvider";
import AddBankDetail from "./components/Dashboard/ServiceProvider/AddBankDetail";
import AddServiceDetails from "./components/Dashboard/ServiceProvider/AddServiceDetails";
import ConsumerWallet from "./components/Dashboard/Consumer/Wallet/ConsumerWallet";
import ConsumerBooking from "./components/Dashboard/Consumer/Booking/ConsumerBooking";
import RefferalPage from "./components/Dashboard/Consumer/Referral/RefferalPage";
import ServiceManagement from "./components/Dashboard/ServiceManagement/ServiceManagementPage";
import AddNewService from "./components/Dashboard/ServiceManagement/AddNewService";
import BookingDetails from "./components/Dashboard/Consumer/Booking/BookingDetails";
import ViewAttendance from "./components/Dashboard/Consumer/Booking/ViewAttendance";
import ViewReview from "./components/Dashboard/Consumer/Booking/ViewReview";
import ServiceProviderDetail from "./components/Dashboard/ServiceProvider/ServiceProviderDetail";
import ScrollToTop from "./components/ScrollToTop.js/ScrollToTop";
import Assesments from "./components/Dashboard/ServiceProvider/Assesments";
import BankDetails from "./components/Dashboard/ServiceProvider/ViewBankDetails";
import ViewServiceDetails from "./components/Dashboard/ServiceProvider/ViewServiceDetails";
import ViewBookingDetails from "./components/Dashboard/ServiceProvider/ViewBookings";
import ViewBookings from "./components/Dashboard/ServiceProvider/ViewBookings";
import ViewBookingDetail from "./components/Dashboard/ServiceProvider/ViewBookingDetail";
import ReviewService from "./components/Dashboard/ServiceManagement/ReviewService";
import ConsumerUsingService from "./components/Dashboard/ServiceManagement/ConsumerUsingService";
import ServiceProvingService from "./components/Dashboard/ServiceManagement/ServiceProvingService";
import FinanceManagementPage from "./components/FinanceManagement/FinanceManagementPage";
import Refund from "./components/FinanceManagement/Refund/Refund";
import ViewRefundDetail from "./components/FinanceManagement/Refund/ViewRefundDetail";
import MakeRefund from "./components/FinanceManagement/Refund/MakeRefund";
import ReferralsPage from "./components/FinanceManagement/Referrals/ReferralsPage";
import ReferalManagement from "./components/FinanceManagement/Referrals/ReferalManagement";
import CouponOfferPage from "./components/FinanceManagement/CouponOffer/CouponOfferPage";
import AddNewCoupon from "./components/FinanceManagement/CouponOffer/AddNewCoupon";
import ReviewCoupon from "./components/FinanceManagement/CouponOffer/ReviewCoupon";
import TaxManagementPage from "./components/FinanceManagement/TaxManagement/TaxManagementPage";
import ServiceProviderPaymentPage from "./components/FinanceManagement/ServiceProviderPayment/ServiceProviderPaymentPage";
import ViewServiceProviderPayment from "./components/FinanceManagement/ServiceProviderPayment/ViewServiceProviderPayment";
import SalaryPayment from "./components/FinanceManagement/ServiceProviderPayment/SalaryPayment";
import HelpSuppportPage from "./components/Dashboard/HelpSuppport/HelpSuppportPage";
import ViewTicketDetail from "./components/Dashboard/HelpSuppport/ViewTicketDetail";
import ContentManagementPage from "./components/Dashboard/ContentManagement/ContentManagementPage";
import HomeBanner from "./components/Dashboard/ContentManagement/HomeBanner";
import FAQManager from "./components/Dashboard/ContentManagement/faqs/FAQManager";
import ReplaceServicePrvider from "./components/Dashboard/ContentManagement/ReplaceServicePrvider/ReplaceServicePrvider";
import BookingTC from "./components/Dashboard/ContentManagement/BookingTC";
import ServiceGuidelines from "./components/Dashboard/ContentManagement/ServiceGuidelines/ServiceGuidelines";
import AntiDescrimination from "./components/Dashboard/ContentManagement/AntiDescrimination/AntiDescrimination";
import PrivacyPolicy from "./components/Dashboard/ContentManagement/PrivacyPolicy/PrivacyPolicy";
import TermCondition from "./components/Dashboard/ContentManagement/TermCondition/TermCondition";
import AboutUs from "./components/Dashboard/ContentManagement/AboutUs/AboutUs";
import BasicPay from "./components/Dashboard/ContentManagement/ServiceProviderContent/BasicPay/BasicPay";
import BasicPayDetail from "./components/Dashboard/ContentManagement/ServiceProviderContent/BasicPay/BasicPayDetail";
import AddNewPay from "./components/Dashboard/ContentManagement/ServiceProviderContent/BasicPay/AddNewPay";
import AssessmentsPage from "./components/Dashboard/ContentManagement/assessments/AssessmentsPage";
import AssessmentDetailPage from "./components/Dashboard/ContentManagement/assessments/AssessmentDetailPage";
import AssessmentResultPage from "./components/Dashboard/ContentManagement/assessments/AssessmentResultPage";
import AssessmentResultDetail from "./components/Dashboard/ContentManagement/assessments/AssessmentResultDetail";
import LeaveReasonPage from "./components/Dashboard/ContentManagement/Leaves/LeaveReasonPage";
import DeclineLeaves from "./components/Dashboard/ContentManagement/Leaves/DeclineLeaves";
import FairPolicy from "./components/Dashboard/ContentManagement/FairPolicy/FairPolicy";
import NotificationManagementPage from "./components/Dashboard/Notifications/NotificationManagementPage";
import ReviewNotification from "./components/Dashboard/Notifications/ReviewNotification";
import SendNotificationPage from "./components/Dashboard/Notifications/SendNotificationPage";
import RoleManagementPage from "./components/Dashboard/RoleManagement/RoleManagementPage";
import AddNewRole from "./components/Dashboard/RoleManagement/AddNewRole";
import LoginCredentials from "./components/Dashboard/RoleManagement/LoginCredentials";
import ReviewRolePage from "./components/Dashboard/RoleManagement/ReviewRole";
import SettingsPage from "./components/Dashboard/Settings/SettingsPage";
import ProfilePage from "./components/Dashboard/Settings/ProfilePage";
import ChangePassword from "./components/Dashboard/Settings/ChangePassword";
import NotificationList from "./components/Dashboard/Settings/NotificationList";
import LogoutConfirmation from "./components/Dashboard/Settings/LogoutConfirmation";
import Login from "./components/Dashboard/Settings/Login";

function AppContent() {
  const location = useLocation();
  const hideSidebarAndNavbar =
    location.pathname === "/logout" || location.pathname === "/login";
  return (
    <div>
      {/* <ScrollToTop /> */}
      <div className="flex">
        {!hideSidebarAndNavbar && <Sidebar />}
        <div className="flex-1">
          {!hideSidebarAndNavbar && <Navbar />}
          <div
            className={`${
              hideSidebarAndNavbar
                ? ""
                : "ml-60 mt-16 px-8 bg-[#F5F6FA]  h-[calc(100vh-4rem)] overflow-y-auto"
            } `}
          >
            <div className={hideSidebarAndNavbar ? "" : "md:mt-10"}>
              <Routes>
                <Route path="/" element={<Home />} />
                {/* --consumer--  */}
                <Route path="/consumer" element={<ConsumerPage />} />
                <Route path="/add-consumer" element={<AddNewConsumer />} />
                <Route path="/review" element={<ConsumerDetails />} />
                {/* document */}
                <Route path="/upload-doc" element={<UploadDocument />} />
                {/* wallet */}
                <Route path="/view-wallet" element={<ConsumerWallet />} />
                {/* customer bookings */}
                <Route path="/booking" element={<ConsumerBooking />} />
                <Route path="/booking-review" element={<BookingDetails />} />
                <Route path="/attendance-leaves" element={<ViewAttendance />} />
                <Route path="/customer-reviews" element={<ViewReview />} />
                {/* refferal */}
                <Route path="/refer" element={<RefferalPage />} />
                {/* service provider */}
                <Route
                  path="/service-provider"
                  element={<ServiceProviderPage />}
                />
                <Route
                  path="/review-service-provider"
                  element={<ServiceProviderDetail />}
                />
                <Route path="/assements" element={<Assesments />} />
                <Route path="/view-bank-detail" element={<BankDetails />} />
                <Route
                  path="/view-service-details"
                  element={<ViewServiceDetails />}
                />
                <Route path="/view-bookings" element={<ViewBookings />} />
                <Route
                  path="/view-booking-details"
                  element={<ViewBookingDetail />}
                />
                <Route
                  path="/add-service-provider"
                  element={<AddNewServiceProvider />}
                />
                <Route path="/upload-bank-detail" element={<AddBankDetail />} />
                <Route path="/add-service" element={<AddServiceDetails />} />
                {/* services */}
                <Route path="/services" element={<ServiceManagement />} />
                <Route path="/add-new-service" element={<AddNewService />} />
                <Route path="/review/:id" element={<ReviewService />} />
                <Route
                  path="/service-details/:id"
                  element={<ReviewService />}
                />
                <Route
                  path="/detail-page/:serviceName"
                  element={<ConsumerUsingService />}
                />
                <Route
                  path="/detail-service-page/:serviceName"
                  element={<ServiceProvingService />}
                />
                {/* --- Finances */}
                <Route path="/finance" element={<FinanceManagementPage />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="/refund-detail" element={<ViewRefundDetail />} />
                <Route path="/makerefund" element={<MakeRefund />} />
                <Route path="/refferal" element={<ReferralsPage />} />
                <Route
                  path="/referral-managemnt"
                  element={<ReferalManagement />}
                />
                <Route path="/coupon-offer" element={<CouponOfferPage />} />
                <Route path="/add-new-coupon" element={<AddNewCoupon />} />
                <Route path="/coupon-review" element={<ReviewCoupon />} />
                <Route path="/tax-management" element={<TaxManagementPage />} />
                <Route
                  path="/service-provider-payment"
                  element={<ServiceProviderPaymentPage />}
                />
                <Route
                  path="/view-provider-payment"
                  element={<ViewServiceProviderPayment />}
                />
                <Route path="/salary-payment" element={<SalaryPayment />} />
                {/* Help & Support */}
                <Route path="/help-support" element={<HelpSuppportPage />} />
                <Route
                  path="/viewticket-detail"
                  element={<ViewTicketDetail />}
                />
                {/* content management */}
                <Route
                  path="/content-manage"
                  element={<ContentManagementPage />}
                />
                <Route path="/home-banner" element={<HomeBanner />} />
                <Route path="/faq-manager" element={<FAQManager />} />
                <Route
                  path="/replace-service-provider"
                  element={<ReplaceServicePrvider />}
                />
                <Route path="/booking-tc" element={<BookingTC />} />
                <Route path="/service-guide" element={<ServiceGuidelines />} />
                <Route path="/anti-desc" element={<AntiDescrimination />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/term-condition" element={<TermCondition />} />
                <Route path="/about" element={<AboutUs />} />

                {/* service provider content */}
                <Route path="/basic-pay" element={<BasicPay />} />
                <Route path="/add-basic-pay" element={<AddNewPay />} />
                <Route path="/basic-pay-detail" element={<BasicPayDetail />} />
                <Route path="/assessments" element={<AssessmentsPage />} />
                <Route
                  path="/assessment-detail"
                  element={<AssessmentDetailPage />}
                />
                <Route
                  path="/assessment-result"
                  element={<AssessmentResultPage />}
                />
                <Route
                  path="/assessment-result-detail"
                  element={<AssessmentResultDetail />}
                />
                <Route path="/leave-reason" element={<LeaveReasonPage />} />
                <Route
                  path="/decline-leave-reason"
                  element={<DeclineLeaves />}
                />
                <Route path="/fair-policy" element={<FairPolicy />} />

                {/* notification management */}
                <Route
                  path="notifications"
                  element={<NotificationManagementPage />}
                />

                <Route
                  path="/review-notification"
                  element={<ReviewNotification />}
                />
                <Route
                  path="/send-notification"
                  element={<SendNotificationPage />}
                />

                {/* role management */}

                <Route
                  path="/role-management"
                  element={<RoleManagementPage />}
                />
                <Route path="/add-role" element={<AddNewRole />} />
                <Route
                  path="/add-log-credential"
                  element={<LoginCredentials />}
                />
                <Route path="/review-role" element={<ReviewRolePage />} />

                {/*settings  */}
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route
                  path="/notification-list"
                  element={<NotificationList />}
                />
                <Route path="/logout" element={<LogoutConfirmation />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}
export default App;
