import React from "react";
import { useNavigate } from "react-router-dom";
import GenericTC from "../GenericTc";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const bookingData = {
    title: "Privacy Policy",
    termsAndConditions: [
      {
        label: "Privacy Policy Body Content",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  };

  const handleSave = (data) => {
    console.log("Booking-specific save logic", data);
  };

  return (
    <GenericTC
      initialTitle={bookingData.title}
      termsList={bookingData.termsAndConditions}
      onSave={handleSave}
      goBack={() => navigate(-1)}
    />
  );
};

export default PrivacyPolicy;
