import React, { useState } from "react";
import ButtonGroup from "../../Buttons/ButtonGroup";

const Localization = () => {
  const [localization, setlocalization] = useState({
    dateformat: "",
    timeformat: "",
    timezone: "",
    language: "",
    disablelang: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setlocalization({
      ...localization,
      [name]: value,
    });
  };

  const handleSave = () => {
    alert("data saved!");
  };

  return (
    <div className="">
      <div
        style={{ backgroundColor: "rgba(181, 228, 202, 0.25)" }}
        className="p-2 rounded-md mb-6"
      >
        <p className="text-[12px] font-nunito text-green font-normal ">
          These information will be displayed on invoices/bills/payments and
          other PDF documents where company info is required.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-6 bg-white rounded-lg shadow p-6">
        {[
          {
            label: "Date Format",
            name: "dateformat",
            options: ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY-MM-DD"],
          },
          {
            label: "Time Format",
            name: "timeformat",
            options: ["12-hour", "24-hour"],
          },
          {
            label: "Default Timezone",
            name: "timezone",
            options: ["GMT", "UTC", "EST", "PST"],
          },
          {
            label: "Default Language",
            name: "language",
            options: ["English", "Spanish", "French", "German"],
          },
          {
            label: "Disable Languages",
            name: "disablelang",
            options: ["None", "Spanish", "French"],
          },
        ].map(({ label, name, options }) => (
          <div key={name}>
            <label className="block text-[#606060] font-base font-semibold font-nunito mb-2">
              {label}
            </label>
            <select
              name={name}
              value={localization[name]}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 text-[#A6A6A6] text-sm  font-normal font-nunito"
            >
              <option value="" disabled>
                Select
              </option>
              {options.map((option) => (
                <option key={option} value={option} className="text-black">
                  {option}
                </option>
              ))}
            </select>
          </div>
        ))}

        <div className="mt-16 flex justify-end">
          <ButtonGroup
            buttons={[
              {
                label: "Save Changes",
                variant: "save",
                onClick: handleSave,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Localization;
