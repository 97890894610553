import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import badgeData from "../../../data/content/badge";
import Title from "../../../Title/Title";
import ButtonGroup from "../../../Buttons/ButtonGroup";

const AssessmentResultDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //   const { userType } = location.state || { userType: "Consumer" };

  const { badgeId, userType } = location.state || { badgeId: 1 };

  const badgeDetail = badgeData.find((badge) => badge.id === badgeId);

  if (!badgeDetail) {
    return <p>Badge not found</p>;
  }

  return (
    <div className="">
      <div className="flex  gap-8 items-center mb-6">
        <Title title={badgeDetail.badge} goBack={() => navigate(-1)} />
        <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
          {userType}
        </button>
      </div>
      <div className="bg-white rounded-xl shadow-md p-6 h-screen mb-8">
        <div className="mb-4">
          <label className="block text-gray-500 text-sm font-medium mb-2">
            Result (Badge) Name
          </label>
          <input
            type="text"
            value={badgeDetail.badge}
            disabled
            className="w-full p-3 bg-gray-100 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-500 text-sm font-medium mb-2">
            Total Percentage Range (Points) to gain the badge
          </label>
          <input
            type="text"
            value={badgeDetail.pointsRange}
            disabled
            className="w-full p-3 bg-gray-100 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex gap-4 mt-16">
          <ButtonGroup
            buttons={[
              {
                label: "Delete",
                variant: "discard",
                // onClick: () => setIsEditing(false),
              },
              {
                label: "Discard",
                variant: "delete",
                // onClick: toggleEdit
              },
              {
                label: "Save",
                variant: "save",
                //  onClick: saveChanges
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default AssessmentResultDetail;
