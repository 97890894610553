import React from "react";

const PopUpMessage = ({
  isOpen,
  icon,
  title,
  message,
  onCancel,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center px-16 bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-2xl shadow-lg px-10 py-8">
        {/* Icon */}
        {icon && (
          <div className="flex justify-center mb-4">
            <img src={icon} alt="Popup Icon" className="w-12 h-12" />
          </div>
        )}
        <h2 className="text-xl text-center font-lato font-semibold text-black mb-4">
          {title}
        </h2>
        <p className="text-[#7B7B7B] font-lato text-xl  leading-[-0.071px] text-center font-medium">
          {message}
        </p>
        <div className="flex justify-center gap-4 mt-6 text-xl font-lato font-bold">
          <button
            onClick={onCancel}
            className="px-16 py-2 border border-green text-green rounded"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-16 py-2 bg-green text-white rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpMessage;
