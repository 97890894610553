import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import UploadButton from "../../Buttons/UploadButton";

const ContentManagementPage = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("consumer");

  // consumer handlers
  const handleHomeBanner = () => {
    navigate("/home-banner", { state: { userType: activeButton } });
  };

  const handleFaq = () => {
    navigate("/faq-manager", { state: { userType: activeButton } });
    // setActiveButton("consumer");
  };

  const handleReplaceServiceProvider = () => {
    navigate("/replace-service-provider");
    setActiveButton("serviceProvider");
  };

  const handleBookingTc = () => {
    navigate("/booking-tc");
    setActiveButton("consumer");
  };

  const handleServiceGuide = () => {
    navigate("/service-guide");
    setActiveButton("consumer");
  };

  const handleAntiDiscriminatory = () => {
    navigate("/anti-desc");
    setActiveButton("consumer");
  };

  const handlePrivacy = () => {
    navigate("/privacy");
    setActiveButton("consumer");
  };

  const handleTermCondition = () => {
    navigate("/term-condition");
    setActiveButton("consumer");
  };

  const handleAbout = () => {
    navigate("/about");
    setActiveButton("consumer");
  };

  // service provider handlers
  const handleBasicPay = () => {
    navigate("/basic-pay", { state: { userType: activeButton } });
  };
  const handleAssessments = () => {
    navigate("/assessments", { state: { userType: activeButton } });
  };
  const handleResult = () => {
    navigate("/assessment-result", { state: { userType: activeButton } });
  };
  const handleLeave = () => {
    navigate("/leave-reason", { state: { userType: activeButton } });
  };
  const handleDeclineLeave = () => {
    navigate("/decline-leave-reason", { state: { userType: activeButton } });
  };
  const handleFairPolicy = () => {
    navigate("/fair-policy", {
      state: { userType: activeButton },
    });
  };
  return (
    <div>
      <div className="flex flex-row gap-8">
        <Title title="Content Management" goBack={() => navigate(-1)} />

        <div
          className={`rounded-lg ${
            activeButton === "consumer"
              ? "bg-black text-white"
              : "bg-white text-black"
          } text-black font-lato item-center`}
        >
          <button
            className={`px-10 text-sm py-2 font-bold rounded-lg ${
              activeButton === "consumer" ? "text-white" : "text-black"
            }`}
            onClick={() => setActiveButton("consumer")}
          >
            CONSUMER
          </button>
        </div>

        <div
          className={`rounded-lg ${
            activeButton === "serviceProvider"
              ? "bg-black text-white"
              : "bg-white text-black"
          } border border-black text-white font-lato item-center`}
        >
          <button
            className={`px-10 text-sm py-2 font-bold rounded-lg ${
              activeButton === "serviceProvider" ? "text-white" : "text-black"
            }`}
            onClick={() => setActiveButton("serviceProvider")}
          >
            SERVICE PROVIDER
          </button>
        </div>
      </div>

      <div className="container mt-12">
        {activeButton === "consumer" ? (
          <>
            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Homescreen Banner"
                badge="Total 3 Banners"
                handleUpload={handleHomeBanner}
              />
              <UploadButton
                label="FAQs"
                badge="Total 4 FAQs"
                handleUpload={handleFaq}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Replace Service Provider Reasons"
                badge="Reasons & Terms and Conditions"
                handleUpload={handleReplaceServiceProvider}
              />
              <UploadButton
                label="Booking Details T&C"
                badge="Terms and Conditions"
                handleUpload={handleBookingTc}
              />
            </div>
            <p className="text-[#202224] text-2xl font-nunito font-medium mb-3">
              More Options
            </p>
            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Service Guidelines"
                handleUpload={handleServiceGuide}
              />
              <UploadButton
                label="Anti-Discriminatory Policy"
                handleUpload={handleAntiDiscriminatory}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Privacy Policy"
                handleUpload={handlePrivacy}
              />
              <UploadButton
                label="Terms & Conditions"
                handleUpload={handleTermCondition}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton label="About Us" handleUpload={handleAbout} />
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Homescreen Banner"
                badge="Total 3 Banners"
                handleUpload={handleHomeBanner}
              />
              <UploadButton
                label="FAQs"
                badge="Total 4 FAQs"
                handleUpload={handleFaq}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Basic Pays"
                badge="Basic Service Charges in different locations"
                handleUpload={handleBasicPay}
              />

              <UploadButton
                label="Assessments"
                badge="Total 7 Assessments"
                handleUpload={handleAssessments}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Assessment Result (Badges)"
                badge="Total 3 Result Badges"
                handleUpload={handleResult}
              />

              <UploadButton
                label="Leave Reasons"
                badge="Reasons & Terms and Conditions"
                handleUpload={handleLeave}
              />
            </div>
            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Decline Client Request Reasons"
                badge="Reasons & Terms and Conditions"
                handleUpload={handleDeclineLeave}
              />
            </div>

            <p className="text-[#202224] text-2xl font-nunito font-medium mb-3">
              More Options
            </p>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Service Guidelines"
                handleUpload={handleServiceGuide}
              />
              <UploadButton
                label="Anti-Discriminatory Policy"
                handleUpload={handleAntiDiscriminatory}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Privacy Policy"
                handleUpload={handlePrivacy}
              />
              <UploadButton
                label="Fair Policy"
                handleUpload={handleFairPolicy}
              />
            </div>

            <div className="grid grid-cols-2 gap-9">
              <UploadButton
                label="Terms & Conditions"
                handleUpload={handleAbout}
              />
              <UploadButton label="About Us" handleUpload={handleAbout} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContentManagementPage;
