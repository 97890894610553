import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericTC from "../GenericTc";
import editIcon from "../../../../assets/edit.png";
import deleteIcon from "../../../../assets/del.png";
import teamImage from "../../../../assets/team.png";
import ButtonGroup from "../../../Buttons/ButtonGroup";

const AboutUs = () => {
  const navigate = useNavigate();
  const [teamImg, setTeamImg] = useState(teamImage); // Initial image
  const [error, setError] = useState("");

  const aboutData = {
    title: "About Us",
    termsAndConditions: [
      {
        label: "Privacy Policy",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
      {
        label: "Terms of Service",
        content:
          "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
    ],
  };

  const handleSave = (data) => {
    console.log("Booking-specific save logic", data);
  };

  const handleEdit = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 1048576) {
          setError("Image should not be larger than 1MB.");
        } else {
          const reader = new FileReader();
          reader.onload = () => {
            setTeamImg(reader.result);
            setError("");
          };
          reader.readAsDataURL(file);
        }
      }
    };
    input.click();
  };

  const handleDelete = () => {
    setTeamImg(null);
  };

  return (
    <div className="">
      <GenericTC
        initialTitle={aboutData.title}
        termsList={aboutData.termsAndConditions}
        onSave={handleSave}
        showButtons={false}
        goBack={() => navigate(-1)}
      />
      <div className=" bg-white px-6 rounded-md  ">
        <h3 className="text-sm  font-medium font-nunito text-[#606060] mb-4">
          Meet Our Team
        </h3>
        <div className="flex items-center gap-6">
          <div className="relative">
            {teamImg ? (
              <img
                src={teamImg}
                alt="Team"
                className="w-[350px] h-[150px] object-cover  rounded"
              />
            ) : (
              <div className="w-[250px] h-[450px] bg-gray-100 border border-gray-300 rounded flex items-center justify-center text-gray-400">
                No Image
              </div>
            )}
          </div>
          <div className="flex flex-col justify-between mb-9">
            <div className="flex flex-row gap-2">
              <button
                onClick={handleEdit}
                className="flex items-center justify-start  "
              >
                <img src={editIcon} alt="Edit" className="w-5 h-5" />
              </button>
              <button
                onClick={handleDelete}
                className="flex  justify-start items-start  "
              >
                <img src={deleteIcon} alt="Delete" className="w-5 h-5" />
              </button>
            </div>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <p className="text-xs text-green font-lato font-normal  mt-2">
              Note: Image should not be larger than 1MB (250x450).
            </p>
          </div>
        </div>

        <div className="">
          <div className="mt-16 pb-8 mb-8">
            <ButtonGroup
              buttons={[
                { label: "Delete", variant: "discard" },
                { label: "Discard", variant: "delete" },
                { label: "Save", variant: "save" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
