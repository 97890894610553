import React, { useState } from "react";
import ButtonGroup from "../../Buttons/ButtonGroup";

const Pdf = () => {
  const [pdfInfo, setpdfInfo] = useState({
    font: "",
    defaultFont: "10",
    color: "#000000",
    logowidth: "150px",
    pagepdf: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setpdfInfo({
      ...pdfInfo,
      [name]: value,
    });
  };

  const handleSave = () => {
    console.log("PDF information submitted:", pdfInfo);
    alert("PDF information saved successfully!");
  };

  return (
    <div className="">
      <div
        style={{ backgroundColor: "rgba(181, 228, 202, 0.25)" }}
        className="p-2 rounded-md mb-6"
      >
        <p className="text-[12px] font-nunito text-green font-normal ">
          These information will be displayed on invoices/bills/payments and
          other PDF documents where company info is required.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-6 bg-white rounded-lg shadow p-6">
        {[
          {
            label: "PDF Font",
            name: "font",
            type: "dropdown",
            options: ["Arial", "Helvetica", "Times New Roman", "Courier New"],
          },
          {
            label: "Default Font Size",
            name: "defaultFont",
            type: "text",
          },
          {
            label: "Heading Color",
            name: "color",
            type: "color",
          },
          {
            label: "Logo Width",
            name: "logowidth",
            type: "text",
          },
          {
            label: "Show Page number on PDF",
            name: "pagepdf",
            type: "dropdown",
            options: ["Yes", "No"],
          },
        ].map(({ label, name, type, options }) => (
          <div key={name}>
            <label className="block text-[#606060] font-base font-semibold font-nunito mb-2">
              {label}
            </label>
            {type === "dropdown" ? (
              <select
                name={name}
                value={pdfInfo[name]}
                onChange={handleChange}
                className="w-full border border-gray-300 text-[#A6A6A6] rounded-lg p-2 "
              >
                <option
                  value=""
                  disabled
                  className=" font-nunito  text-sm font-normal"
                >
                  <p className="text-[#A6A6A6]">Select</p>
                </option>
                {options.map((option) => (
                  <option key={option} value={option} className="text-black">
                    {option}
                  </option>
                ))}
              </select>
            ) : type === "color" ? (
              <input
                type="color"
                name={name}
                value={pdfInfo[name]}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-2 "
              />
            ) : (
              <input
                type="text"
                name={name}
                value={pdfInfo[name]}
                onChange={handleChange}
                placeholder="Enter Here"
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring focus:ring-green-300 focus:outline-none"
              />
            )}
          </div>
        ))}

        <div className="mt-16 flex justify-end">
          <ButtonGroup
            buttons={[
              {
                label: "Save Changes",
                variant: "save",
                onClick: handleSave,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Pdf;
