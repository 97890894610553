export const totalData = {
  states: [
    {
      state: "Chennai",
      services: [
        {
          serviceCategory: "Cooking",
          parameters: [
            {
              selectParameter: "Per Person",
              chargesPerMonth: 3000,
            },
          ],
        },
        {
          serviceCategory: "Cleaning",
          parameters: [
            {
              selectParameter: "Per BHK",
              chargesPerMonth: 4000,
            },
          ],
        },
      ],
    },
    {
      state: "Delhi",
      services: [
        {
          serviceCategory: "Cooking",
          parameters: [
            {
              selectParameter: "Per Person",
              chargesPerMonth: 3500,
            },
          ],
        },
        {
          serviceCategory: "Cleaning",
          parameters: [
            {
              selectParameter: "Per BHK",
              chargesPerMonth: 4500,
            },
          ],
        },
        {
          serviceCategory: "Gardening",
          parameters: [
            {
              selectParameter: "Per Square Meter",
              chargesPerMonth: 2000,
            },
          ],
        },
      ],
    },
  ],
};
