import React, { useState } from "react";
import ButtonGroup from "../../Buttons/ButtonGroup";

const CompanyInformationTab = () => {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    address: "",
    city: "",
    state: "",
    countryCode: "",
    zipCode: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo({
      ...companyInfo,
      [name]: value,
    });
  };

  const handleSave = () => {
    console.log("Company information submitted:", companyInfo);
    alert("Company information saved successfully!");
  };

  return (
    <div className="">
      <div
        style={{ backgroundColor: "rgba(181, 228, 202, 0.25)" }}
        className="p-2 rounded-md mb-6"
      >
        <p className="text-[12px] font-nunito text-green font-normal ">
          These information will be displayed on invoices/bills/payments and
          other PDF documents where company info is required.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-6 bg-white rounded-lg shadow p-6">
        {[
          { label: "Company Name", name: "companyName" },
          { label: "Address", name: "address" },
          { label: "City", name: "city" },
          { label: "State", name: "state" },
          { label: "Country Code", name: "countryCode" },
          { label: "Zip Code", name: "zipCode" },
          { label: "Phone", name: "phone" },
        ].map(({ label, name }) => (
          <div key={name}>
            <label className="block text-[#606060] font-base font-semibold font-nunito mb-2">
              {label}
            </label>
            <input
              type="text"
              name={name}
              value={companyInfo[name]}
              onChange={handleChange}
              placeholder="Enter Here"
              className="w-full border border-gray-300 rounded-lg p-2 focus:ring focus:ring-green-300 focus:outline-none"
            />
          </div>
        ))}

        <div className="mt-16 flex justify-end">
          <ButtonGroup
            buttons={[
              {
                label: "Save Changes",
                variant: "save",
                onClick: handleSave,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInformationTab;
