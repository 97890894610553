import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../../Title/Title";
import faqData from "../../../data/content/faq";
import edit from "../../../../assets/edit.png";
import del from "../../../../assets/del.png";
import ButtonGroup from "../../../Buttons/ButtonGroup";

const FAQManager = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = location.state || { userType: "Consumer" };
  const [faqs, setFaqs] = useState(faqData);
  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({ question: "", answer: "" });
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);

  const handleEdit = (id) => {
    const faqToEdit = faqs.find((faq) => faq.id === id);
    setFormData(faqToEdit);
    setEditingId(id);
  };

  const handleSave = (id) => {
    setFaqs((prev) =>
      prev.map((faq) => (faq.id === id ? { ...faq, ...formData } : faq))
    );
    setEditingId(null);
    setFormData({ question: "", answer: "" });
  };

  const handleDelete = (id) => {
    setFaqs((prev) => prev.filter((faq) => faq.id !== id));
  };

  const handleAdd = () => {
    setFaqs((prev) => [...prev, { id: Date.now(), ...formData }]);
    setFormData({ question: "", answer: "" });
    setIsAddFormVisible(false); // Hide form after adding
  };

  const toggleAddForm = () => {
    setIsAddFormVisible((prev) => !prev);
  };

  return (
    <div className="  min-h-screen">
      <header className="flex justify-between items-center mb-6">
        <div className="flex gap-8">
          <Title title="FAQs" goBack={() => navigate(-1)} />
          <button className="bg-black text-white capitalize py-2 px-6 rounded-lg">
            {userType}
          </button>
        </div>
      </header>

      <div className=" p-6 bg-white  rounded-2xl ">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className=" py-4 flex items-center  justify-between"
          >
            <div className="flex-1">
              <h3 className="font-semibold text-[#606060] font-nunito text-sm">
                {faq.question}
              </h3>
              <textarea
                value={editingId === faq.id ? formData.answer : faq.answer}
                onChange={(e) => {
                  if (editingId === faq.id) {
                    setFormData({ ...formData, answer: e.target.value });
                  }
                }}
                className={`border p-2 rounded w-full mt-2 text-sm font-nunito font-normal ${
                  editingId === faq.id ? "bg-white" : "bg-gray-100"
                }`}
                readOnly={editingId !== faq.id}
              />
            </div>

            <div className="flex space-x-2 ml-4">
              {editingId === faq.id ? (
                <button
                  onClick={() => handleSave(faq.id)}
                  className="px-4 py-2 bg-green-500 text-black rounded"
                >
                  Save
                </button>
              ) : (
                <div onClick={() => handleEdit(faq.id)}>
                  <img src={edit} className="h-5 w-5" alt="Edit" />
                </div>
              )}

              <div onClick={() => handleDelete(faq.id)}>
                <img src={del} className="h-5 w-5" alt="Delete" />
              </div>
            </div>
          </div>
        ))}

        <div className="mt-6">
          {isAddFormVisible && (
            <div className="flex flex-col space-y-2 mt-4">
              <input
                type="text"
                value={formData.question}
                onChange={(e) =>
                  setFormData({ ...formData, question: e.target.value })
                }
                placeholder="New question"
                className="border p-2 rounded"
              />
              <textarea
                value={formData.answer}
                onChange={(e) =>
                  setFormData({ ...formData, answer: e.target.value })
                }
                placeholder="New answer"
                className="border p-2 rounded"
              />
              <button
                onClick={handleAdd}
                className="px-4 py-2 bg-green-500 text-white rounded shadow"
              >
                Save FAQ
              </button>
            </div>
          )}
          <button
            onClick={toggleAddForm}
            className="px-4 py-2 text-sm font-lato font-normal w-44 text-green border border-green border-dashed rounded shadow"
          >
            {isAddFormVisible ? "Save" : "+ Add FAQ"}
          </button>
        </div>

        <div className="flex gap-6 font-lato md:text-sm font-normal capitalize lg:mt-16">
          <ButtonGroup
            buttons={[
              { label: "Delete", variant: "discard" },
              { label: "Discard", variant: "delete" },
              { label: "Save", variant: "save" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default FAQManager;
