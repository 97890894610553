import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";
import CompanyInformationTab from "./CompanyInformationTab"; // Import the component
import Localization from "./Localization";
import Pdf from "./pdf";
import ActivityLog from "./ActivityLog";

const SettingsPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("General Settings");
  const [fullLogo, setFullLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");

  const handleFileUpload = (e, setFile) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("fullLogo", fullLogo);
    formData.append("favicon", favicon);
    formData.append("companyName", companyName);
    formData.append("companyDetails", companyDetails);

    console.log("Form data submitted:", {
      fullLogo,
      favicon,
      companyName,
      companyDetails,
    });
    alert("Settings saved successfully!");
  };

  return (
    <>
      <div className="mb-4">
        <Title title="Settings" goBack={() => navigate(-1)} />
      </div>
      <div className="min-h-screen bg-gray-50 mb-8 flex">
        {/* Sidebar Tabs */}
        <div className="w-1/4 bg-white rounded-lg h-min shadow p-4">
          <ul className="space-y-2">
            {[
              "General Settings",
              "Company Information",
              "Localization",
              "PDFs",
              "Activity Logs",
            ].map((tab) => (
              <li
                key={tab}
                className={`cursor-pointer p-2 rounded-lg font-nunito text-[#202224] ${
                  activeTab === tab
                    ? "bg-[#B5E4CA] font-semibold text-[#202224]"
                    : "font-semibold"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="w-3/4 ml-6">
          {activeTab === "General Settings" && (
            <div className="bg-white rounded-lg shadow p-6">
              {/* General Settings Content */}
              <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
                {/* Full Logo Upload */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Add Full Logo Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, setFullLogo)}
                    className="hidden"
                    id="fullLogoInput"
                  />
                  <label
                    htmlFor="fullLogoInput"
                    className="border-dashed border-2 border-green bg-[#F5F6FA] rounded-lg h-[180px] flex items-center justify-center cursor-pointer"
                  >
                    {fullLogo ? (
                      fullLogo.name
                    ) : (
                      <span className="text-green font-nunito text-2xl font-semibold">
                        + Add Picture
                      </span>
                    )}
                  </label>
                </div>

                {/* Favicon Upload */}
                <div>
                  <label className="block text-gray-700 font-medium mb-2">
                    Add Favicon (Icon) Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, setFavicon)}
                    className="hidden"
                    id="faviconInput"
                  />
                  <label
                    htmlFor="faviconInput"
                    className="border-dashed border-2 w-40 h-40 bg-[#F5F6FA] border-green rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-100"
                  >
                    {favicon ? (
                      favicon.name
                    ) : (
                      <span className="text-green font-semibold font-nunito text-lg">
                        + Add Picture
                      </span>
                    )}
                  </label>
                </div>
              </div>

              {/* Company Information */}
              <div className="mt-6">
                <label className="block text-gray-700 font-medium mb-2">
                  Company Name
                </label>
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter Here"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:ring focus:ring-green-300 focus:outline-none"
                />
              </div>

              <div className="mt-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Company Main Details
                </label>
                <input
                  type="text"
                  value={companyDetails}
                  onChange={(e) => setCompanyDetails(e.target.value)}
                  placeholder="Enter Here"
                  className="w-full border border-gray-300 rounded-lg p-2 focus:ring focus:ring-green-300 focus:outline-none"
                />
              </div>

              <div className="mt-16 flex justify-end">
                <ButtonGroup
                  buttons={[
                    {
                      label: "Save Changes",
                      variant: "save",
                      onClick: handleSave,
                    },
                  ]}
                />
              </div>
            </div>
          )}

          {activeTab === "Company Information" && <CompanyInformationTab />}
          {activeTab === "Localization" && <Localization />}
          {activeTab === "PDFs" && <Pdf />}
          {activeTab === "Activity Logs" && <ActivityLog />}
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
