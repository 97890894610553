import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadButton from "../../Buttons/UploadButton";

const ReviewRolePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {}; // Ensure consumerData is being passed
  const features = [
    {
      name: "Bulk PDF Export",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },
    {
      name: "Invoices",
      permissions: [
        "View (Own)",
        "View (Global)",
        "Create",
        "Edit",
        "Delete",
        "View All Templates",
      ],
    },
  ];
  return (
    <div>
      <div>
        <Title
          title={`${consumerData.name || "User"}`} // Using consumerData.name here
          goBack={() => navigate(-1)}
        />
      </div>
      <div
        style={{ background: `rgba(181, 228, 202, 0.25)` }}
        className="w-[668px] p-3 rounded-md mt-4"
      >
        <p className="text-green font-nunito text-sm font-normal">
          Changing role permissions won't affected current staff members
          permissions that are using this role.
        </p>
        <div className="font-nunito text-sm font-normal">
          <input type="checkbox" className="mr-2 mt-3" />
          Update all staff members permissions that are using this role
        </div>
      </div>
      <div>
        <div className="bg-white p-6 rounded-lg mt-8">
          <div className="flex  gap-8 mb-6">
            <div className="w-1/2 mr-4">
              <div>
                <label className="block font-semibold text-gray-700 mb-2">
                  Role Name
                </label>
                <input
                  type="text"
                  value={consumerData.name}
                  placeholder="Enter Here"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              <div className="mt-6">
                <label className="block font-semibold text-gray-700 mb-2">
                  Total Users
                </label>
                <input
                  type="text"
                  value={consumerData.totalUsers}
                  placeholder="Enter Here"
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
            </div>

            <div className="w-64">
              <div>
                <p className="text-[#606060] text-sm text-left font-semibold ">
                  Staff Members Using This Role
                </p>
              </div>
              <div className="border border-gray-300 rounded w-96 p-4 mt-3">
                {consumerData.users && consumerData.users.length > 0 ? (
                  consumerData.users.map((user, index) => (
                    <div
                      key={user.id || index} // Use a unique key if available (e.g., user.id)
                      className="flex items-center gap-4 mb-4 last:mb-0"
                    >
                      <div className="w-8 h-8 bg-gray-300 rounded-full flex items-center justify-center">
                        {/* Display user's initials */}
                        <span className="text-white font-semibold">
                          {user.name?.charAt(0).toUpperCase() || "A"}
                        </span>
                      </div>
                      {/* Display user's name */}
                      <span className="text-[#202224] text-sm font-semibold font-nunito">
                        {user.name || `Name ${index + 1}`}
                      </span>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">
                    No staff members found for this role.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 font-semibold text-gray-600 mb-4 ">
            <span>Features</span>
            <span className="col-span-2">Permissions</span>
          </div>
          <div className="grid grid-cols-1 w-1/2 border rounded-md border-gray-200">
            {features.map((feature, index) => (
              <div key={index} className="grid grid-cols-2    mb-4 p-4">
                <div>
                  <span className=" bg-[#B5E4CA] w-60 text-[#202224] font-sm font-nunito font-semibold px-4 py-2 rounded-md">
                    {feature.name}
                  </span>
                </div>

                {/* Permissions */}
                <div className=" grid grid-cols-1 gap-2 border rounded-md border-gray-200 p-4">
                  {feature.permissions.map((perm, i) => (
                    <label
                      key={i}
                      className="flex items-center space-x-2  font-lato text-base"
                    >
                      <input
                        type="radio"
                        name={`${feature.name}-perm`}
                        className="accent-green-500"
                      />
                      <span>{perm}</span>
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-16 mb-8 px-8">
            <ButtonGroup
              buttons={[
                { label: "Delete", variant: "delete" },
                { label: "Save", variant: "save" },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewRolePage;
