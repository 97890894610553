import React from "react";
import TotalStat from "./TotalStat/TotalStat";
import userData from "../../data/consumerTable";
import { totalData } from "../../data/totalStatData";
import GenericTable from "../../GenericTable/GenericTable";

function Home() {
  const columns = [
    { header: "User ID", key: "id" },
    { header: "User Name", key: "name" },
    { header: "Category", key: "category" },
    { header: "Gender", key: "gender" },
    { header: "Status", key: "status" },
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Overview</h2>
      <TotalStat data={totalData} />
      <GenericTable
        title="Users"
        columns={columns}
        data={userData}
        redirectTo="/review"
      />
    </div>
  );
}

export default Home;
