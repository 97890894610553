export const getStatusColor = (status) => {
  switch (status) {
    case "verified":
    case "Completed":
    case "Redeemed":
    case "Resolved":
      return "bg-[#08875D] text-white";
    case "pending":
    case "In Progress":
    case "Pending":
    case "Upcoming":
    case "Unused":
      return "bg-[#FCBE2D] text-white";
    case "rejected":
    case "Replaced":
    case "Expired":
    case "Rejected":
      return "bg-[#FD5454] text-white";
    case "Request Rejected":
      return "border border-[#FD5454] text-[#fd5454]";
    case "Request Pending":
      return "border border-[#08875D] text-green";
    default:
      return "bg-gray-600";
  }
};
